/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

/* font family  file added */
@import "react-multi-carousel/lib/styles.css";
@import "./fonts/font.css";
@import "./fonts-inter/font-inter.css";
@import "./landing.css";
@import "./proposal.css";
@import "./single-page-layout.css";
@import "./affiliate.css";
/* font family ends */
/* body{
      font-family: 'Panton-Bold';
 } */
:root {
  --primary: #ff932b;
  --secondary: #0c3668;
  --superSecondary: #1558a6;

  /* --primary: #5caade;
  --secondary: #e67d28;
  --superSecondary: #9aca3d; */
  --titleNewColor: #294260;
  --bluelight250: #edf0f5;
  --bluelight350: #f6f9ff;
  --graylight450: #565c63;
  --graylight480: #5a5b5d;
  --graylight550: #4a525a;
  --graylight520: #424C5E;
  --graydark150: #2e384d;
  --darkblue150: #0e478b;
  --darkblue250: #0c3668;
  --darkblue350: #14437d;
  --skyblue550: #3486e8;
  --textTitleColor: #2e384d;
  --textBodyColor: #5a5b5d;
  --textHeadingColor: #5f7775;
  --headingBlack250: #252c34;
  --graylight650: #60697B;
  --blueGray250: #2F4156;
  --skyBlue350: #5BAADE;
  --redLight150: #FCEDEB;
  --warningColor: #fbedd1;
  --yellow150: #f4a140;
  --buttonBackground: var(--primary);
  --buttonText: #fff;
  --slate150: #dfe8e7;
  --slate40: #edf2f2;
  --vivintOne: #3c535f;
  --vivintTwo: #424e5a;
  --vivintThree: #495057;
  --suntuityOne: #3e9edc;
  --suntuityTwo: #5cabde;
  --suntuityThree: #a2cfed;
  --diffenergyTitle: #2c2f3e;
  --diffenergyPara: #323c50; 
  --diffenergyGray120: #21262D; 

  --grayDark670: #343434;
  --midnightBlue: #073265;
  --grayLight690: #666766;
  --grayLight695: #686868;
  --grayLight720: #74838b;
  --policeBlue120: #3c535f;
  --midnightBlue150: #d5dfff;
  --midBlueLight150: #003cff;
  --blueDark760: #212262;

  --primaryRegular: "ProximaNova-Regular";
  --primaryMedium: "ProximaNova-Medium";
  --primarySemiBold: "ProximaNova-SemiBold";
  

}
html {
  scroll-behavior: smooth;
}
body {
  @apply font-primaryRegular;
  background: #f8f8f8;
}
header {
  -webkit-filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.07));
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.07));
  background-color: #ffffff;
}
.estimate__incentives input[type="checkbox"] {
  height: 0;
  width: 0;
  visibility: hidden;
}
.leading-head {
  line-height: 51px;
}
.z--1 {
  z-index: -1;
}
.home__wrap {
  min-height: calc(100vh - 6rem);
}
.text-xxs {
  font-size: 11.5px;
  line-height: 1.2;

  @apply lg:leading-snug;
}
.thnakyou {
  min-height: calc(100vh - 6rem);
}
.right__image {
  background: url("../public/images/home.png") no-repeat;
  background-size: cover;
  background-position: 0;
  height: calc(100vh - 5rem);
}
.mobile-view-banner {
  background: url("../public/images/home-mobile-view.png");
  background-size: cover;
  max-height: 320px;
  background-position: bottom;
  min-height: 200px;
}
.tabs .tabs__tab svg {
  @apply fill-current mr-2;
}
.tabs .tabs__tab,
.tabs .tabs__tab .shp0 {
  @apply text-bodyColor text-lg font-primaryMedium fill-current cursor-pointer;
}
.tabs .tabs__tab.tabs__tab--selected {
  @apply text-titleColor font-primaryBold sm:text-lg text-base focus:outline-none bg-white relative;
}
.tabs .tabs__tab.tabs__tab--selected.bg-transparent {
  background: transparent;
}
.tabs .tabs__tab.bg-transparent svg {
  fill: none;
}

.h-17rem {
  height: 18.5rem;
}
.css-yk16xz-control,
.css-1pahdxg-control {
  border: 0 !important;
  min-height: auto !important;
  cursor: text !important;
}
.css-yk16xz-control > div:last-child,
.css-1pahdxg-control > div:last-child {
  display: none;
}

.css-yk16xz-control > div:first-child,
.css-1pahdxg-control > div:first-child {
  padding: 0.375rem 0.5rem !important;
}
.css-2b097c-container {
  @apply focus:ring-0 outline-none text-sm xxs:text-base cursor-text;
  ring-shadow: none;
}
.css-1pahdxg-control {
  @apply ring-0 border-0 cursor-text !important;
}
.css-1wa3eu0-placeholder {
  @apply text-bodyColor cursor-text !important;
}
.tabs .tabs__tab.tabs__tab--selected .shp0 {
  @apply text-primary fill-current;
}
.lead select {
  background-image: url("../public/images/select-arrow.png");
  background-repeat: no-repeat;
  background-size: 11px 11px;
  background-position: 96%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
/* .input-focus {
  @apply ring-2 ring-titleColor ring-offset-white;
} */
.timeline-wrap > li {
  @apply text-left border-l-2 border-bodyColor border-opacity-30 relative pl-8 pb-6 ml-3;
  /* padding: 0 0 16px 22px; */
  /* margin-left: 9px; */
}
.timeline-wrap > li:before {
  @apply absolute top-0 rounded-full border-2 border-titleColor bg-titleColor z-30;
  left: -11px;
  content: "";
  height: 20px;
  width: 20px;
}
.timeline-wrap > li:last-child {
  @apply border-l-2 border-transparent;
}
.timeline-wrap > li h3 {
  @apply text-lg leading-none font-primaryMedium text-titleColor mb-1;
}
.timeline-wrap > li p:last-child {
  @apply text-sm font-primaryRegular text-bodyColor;
}

.timline-wrap-li {
}
.EstimateDetailModal__Overlay {
  position: fixed; /* Stay in place */
  z-index: 99; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}
.AmountModal__Overlay {
  position: fixed; /* Stay in place */
  z-index: 99; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}
.ReactModal__Content--after-open {
  max-width: 80vw;
  margin: 0 auto;
  border-radius: 2px !important;
  padding: 0 !important;
  top: 50% !important;
  left: 50% !important;
  -webkit-transform: translate(-50%, -50%) !important;
  -ms-transform: translate(-50%, -50%) !important;
  transform: translate(-50%, -50%) !important;
  right: auto !important;
  bottom: auto !important;
  margin-right: -50% !important;
}
.AmountModal__Overlay .ReactModal__Content--after-open {
  min-width: 540px;
  min-height: 365px;
  top: 50% !important;
  left: 50% !important;
  -webkit-transform: translate(-50%, -50%) !important;
  -ms-transform: translate(-50%, -50%) !important;
  transform: translate(-50%, -50%) !important;
  right: auto !important;
  bottom: auto !important;
  margin-right: -50% !important;
}
@media screen and (max-width: 768px) {
  .AmountModal__Overlay .ReactModal__Content--after-open {
    min-width: 100%;
    min-height: 100%;
  }
}

.estimate__modal .tabs {
  /* position: sticky;
    top: 0; */
  background: #fff;
  /* z-index: 99; */
}
.max-h-image {
  max-height: 512px;
}

.esimate__imageBlock.h-card-high {
  height: 393px;
  border-radius: 4px;
}
.bg-custom {
  background: #ebebeb;
}
.esimate__imageBlock.h-card {
  height: 332px;
  border-radius: 4px;
}
.esimate__imageBlock.h-card > div {
  height: 100%;
  width: 100%;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #fafafa;
}

::-webkit-scrollbar {
  width: 4px;
  background-color: #fff;
}

::-webkit-scrollbar-thumb {
  background-color: #888888;
}

.landing-banner-wrap {
  padding-bottom: 5rem;
  background: #fff;
}

@media screen and (min-width: 1530px) {
}
@media screen and (min-width: 1024px) {
  .home.landing-solar-panels-bay-area-ca {
    max-height: 720px;
  }
}
@media screen and (max-width: 767px) {
  .landing-solar1 {
    height: 100vh;
  }
  .h-17rem {
    height: auto;
  }
  .home__wrap {
    @apply min-h-full;
  }
  .esimate__imageBlock > div {
    height: 280px !important;
  }
  .Toastify__toast-container {
    max-width: 540px !important;
    width: auto !important;
  }
  .inputStyle {
    width: 2.5rem !important;
    height: 2.5rem;
  }
  /* .project-hover-desktop{display: none;} */
  .project-hover-mob {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .project-item .project-hover.project-hover-mob .project-description,
  .project-item .project-hover.project-hover-desktop .project-description {
    font-size: 16px;
  }
  .project-item.project-item-mob .project-hover {
    background-color: rgba(255, 255, 255, 0.8);
  }
  .project-item.project-item-mob .project-hover .project-description {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
    -webkit-transition-duration: 0.5s;
    -o-transition-duration: 0.5s;
    transition-duration: 0.5s;
    -webkit-transition-delay: 0.1s;
    -o-transition-delay: 0.1s;
    transition-delay: 0.1s;
    -webkit-transition: all 0.5s 0.1s;
    -o-transition: all 0.5s 0.1s;
    transition: all 0.5s 0.1s;
  }
  .project-item.project-item-mob .project-title.project-title-main {
    display: none;
  }
  .new-jersey-faq .accordion__panel {
    padding: 16px 0 14px !important;
  }
}
@media screen and (max-width: 480px) {
  .powerwall-image,
  .solar-image {
    margin: 0 auto;
  }
  .mobile-dot-custom .react-multi-carousel-track {
    padding-bottom: 32px;
  }

  /* .file-upload-wrapper:before {
        font-size: 14px;
        padding: 7px 15px;
    }
    .file-upload-wrapper:after {
        font-size: 11px;
        padding: 8.5px 15px;
    } */
}
@media screen and (max-width: 1600px) {
  blue-strip {
    max-width: 100%;
  }
}

.form-error {
  color: red;
  font-size: 12px;
  line-height: 1px;
  position: relative;
}

rect {
  cursor: crosshair;
}

#map {
  margin: 0px;
  padding: 0px;
}

.overlay {
  margin: 0px;
  padding: 0px;
}

.panels-div {
  margin: 0px;
  padding: 0px;
  float: left;
  width: 110px;
  cursor: move;
}

.active > div {
  border: 1px dashed var(--primary) !important;  border: 1px dashed var(--primary) !important;
  border: 1px dashed var(--primary) !important;
  border: 1px dashed var(--primary) !important;

}
.active-block {
  border: 2px solid var(--primary);
  -webkit-box-shadow: 0px 4px 4px #929294;
  box-shadow: 0px 4px 4px #929294;
  border-bottom: none;
}
@media screen and (max-width: 480px) {
  .active-block {
    border: 2px solid var(--primary);
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}

.active-block p.active-percent {
  @apply text-primary;
}

.tool-action-btn {
  background: #00000036;
  border: 0px;
  padding: 5px 4px;
  border: 0;
  margin: 0 2px;
  border-radius: 16px;
  font-size: 17px;
}

.panels-div > div {
  border: 1px solid transparent;
}

.panels-div > div:hover {
  border: 1px dashed var(--primary) !important;
}
button.tool-action-btn.top-left {
  top: 10px !important;
  left: 10px !important;
}
button.tool-action-btn.top-right {
  top: 10px !important;
}
button.tool-action-btn.bottom {
  bottom: 10px !important;
}
.bg-div {
  background: #fff;
  border: 0px;
  padding: 4px 7px;
  -webkit-box-shadow: 0 0 4px 4px rgb(0 0 0 / 30%);
  box-shadow: 0 0 4px 4px rgb(0 0 0 / 30%);
  margin: 0 2px;
  border-radius: 16px;
  font-size: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.top-right .bg-div {
  padding: 4px 5px;
}
.total-count {
  background: #fff;
  padding: 10px;
  font-size: 14px;
}
button.tool-action-btn.adjust {
  top: 0px !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  left: 0 !important;
}
button.tool-action-btn.adjust-done {
  top: 10px !important;
}
button.tool-action-btn.adjust-done .bg-div,
button.tool-action-btn.adjust .bg-div {
  font-size: 14px;
}
.battery-image {
  width: 150px;
  float: left;
  margin: 11px;
  padding: 11px;
  border: 1px solid;
  min-height: 196px;
  height: auto;
  border-radius: 11px;
}
.landing-v2 {
  @apply bg-titleColor;
  /* background: url('../public/images/page-banner.png'); */
}
/* page 1 banner */
.landing-v1 {
  background: url("../public/images/bg/banner.jpg");
  background-size: cover;
  background-position: center;
}
.landing-solar1 {
  background: url("../public/images/solar-bg1.png");
  background-size: cover;
  background-position: center;
  height: 70vh;
}
.landing-solar2 {
  background: url("../public/images/bg/banner.jpg");
  background-size: cover;
  background-position: center;
}
.landing-mission-solar {
  background: url("../public/images/bg/bannertype1.jpeg");
  background-size: cover;
  background-position: center;
}
.about-bg {
  background: url("../public/images/home/why-solar-quote-bg.jpeg");
  background-size: cover;
  background-position: center;
}
.image-filter {
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
}
.banner-height {
  min-height: calc(100vh - 12rem);
}
@media screen and (max-width: 767px) {
  .landing-solar1 {
    height: 100vh;
  }
}
.estimate__incentives label {
  cursor: pointer;
  text-indent: -9999px;
  width: 41px;
  height: 22px;
  background: grey;
  display: block;
  border-radius: 100px;
  position: relative;
}

.estimate__incentives label:after {
  content: "";
  position: absolute;
  top: 2px;
  left: 4px;
  width: 18px;
  height: 18px;
  background: #fff;
  border-radius: 90px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.estimate__incentives input:checked + label {
  @apply bg-secondary;
}

.estimate__incentives input:checked + label:after {
  left: calc(100% - 4px);
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
}

.estimate__incentives label:active:after {
  width: 130px;
}

button {
  @apply focus:outline-none hover:bg-opacity-95 hover:shadow-sm transition ease-in-out duration-150 cursor-pointer;
}
.leading-head {
  line-height: 51px;
}
.z--1 {
  z-index: -1;
}
.home__wrap {
  min-height: calc(100vh - 6rem);
}
.right__image {
  background: url("../public/images/home.png") no-repeat;
  background-size: cover;
  background-position: 0;
  height: calc(100vh - 5rem);
}
.mobile-view-banner {
  background: url("../public/images/home-mobile-view.png");
  background-size: cover;
  max-height: 320px;
  background-position: center;
  min-height: 200px;
}
.tabs .tabs__tab svg {
  @apply fill-current mr-2;
}
.tabs .tabs__tab,
.tabs .tabs__tab .shp0 {
  @apply text-bodyColor text-sm xxs:text-base sm:text-lg font-primaryMedium fill-current cursor-pointer;
}
.tabs .tabs__tab.tabs__tab--selected {
  @apply text-primary font-primaryMedium text-sm xxs:text-base sm:text-lg focus:outline-none bg-white relative;
}
.inner-tab .tabs .tabs__tab.tabs__tab--selected {
  @apply bg-opacity-20 rounded-lg;
}
.inner-tab .tabs .tabs__tab.tabs__tab--selected::after {
  display: none;
}
.Toastify__progress-bar--default {
  background: var(--primary) !important;
}

.tabs.estimate .tabs__tab.tabs__tab--selected {
  @apply text-primary font-primaryMedium text-lg focus:outline-none bg-white relative;
}
.tabs.estimate .tabs__tab.tabs__tab--selected::after {
  @apply w-full bg-primary absolute h-0.5;
  content: "";
  bottom: -2px;
}
.h-17rem {
  height: 18.5rem;
}
.css-yk16xz-control,
.css-1pahdxg-control {
  border: 0 !important;
  min-height: auto !important;
  cursor: text !important;
}
.css-yk16xz-control > div:last-child,
.css-1pahdxg-control > div:last-child {
  display: none;
}
.css-yk16xz-control > div:first-child,
.css-1pahdxg-control > div:first-child {
  padding: 0.5rem !important;
}
.css-2b097c-container {
  @apply focus:ring-0 outline-none text-sm xxs:text-base cursor-text;
  ring-shadow: none;
}
.css-1pahdxg-control {
  @apply ring-0 border-0 cursor-text !important;
}
.css-1wa3eu0-placeholder {
  @apply text-bodyColor cursor-text !important;
}
.tabs .tabs__tab.tabs__tab--selected .shp0 {
  @apply text-primary fill-current;
}
.lead select {
  background-image: url("../public/images/select-arrow.png");
  background-repeat: no-repeat;
  background-size: 11px 11px;
  background-position: 96%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.input-focus {
  @apply ring-2 ring-titleColor ring-offset-white;
}
.timeline-wrap > li {
  @apply text-left border-l-2 border-bodyColor border-opacity-30 relative pl-8 pb-6 ml-3;
  /* padding: 0 0 16px 22px; */
  /* margin-left: 9px; */
}
.timeline-wrap > li:before {
  @apply absolute top-0 rounded-full border-2 border-titleColor bg-titleColor z-30;
  left: -11px;
  content: "";
  height: 20px;
  width: 20px;
}
.timeline-wrap > li:last-child {
  @apply border-l-2 border-transparent;
}
.timeline-wrap > li h3 {
  @apply text-lg leading-none font-primaryMedium text-titleColor mb-1;
}
.timeline-wrap > li p:last-child {
  @apply text-sm font-primaryRegular text-bodyColor;
}
.EstimateDetailModal__Overlay {
  position: fixed; /* Stay in place */
  z-index: 9999; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}
.AmountModal__Overlay {
  position: fixed; /* Stay in place */
  z-index: 9999; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}
.AmountUnitModal__Overlay {
  position: fixed; /* Stay in place */
  z-index: 9999; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}
.ReactModal__Content--after-open {
  max-width: 80vw;
  margin: 0 auto;
  border-radius: 4px !important;
  padding: 0 !important;
}
.AmountModal__Overlay .ReactModal__Content--after-open {
  max-width: 540px;
  max-height: 365px;
}
.payment_overlay.AmountModal__Overlay .ReactModal__Content--after-open {
  max-width: 600px;
  max-height: 100%;
  background: transparent !important;
  border: none !important;
  overflow: hidden !important;
}
.approval_dialog.payment_overlay.AmountModal__Overlay
  .ReactModal__Content--after-open {
  max-width: 700px;
  max-height: 100%;
  background: transparent !important;
  border: none !important;
  overflow: hidden !important;
}
.maxHwight {
  max-height: 90vh;
  overflow: auto;
}
.approval_dialog.payment_overlay .maxHwight {
  max-height: 54vh;
  overflow: auto;
}
::-webkit-scrollbar {
  display: none;
}

/* Demonstrate a "mostly customized" scrollbar
   * (won't be visible otherwise if width/height is specified) */
::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: #aaa; /* or add it to the track */
}

/* Add a thumb */
::-webkit-scrollbar-thumb {
  background: rgb(153, 153, 153);
}

.AmountUnitModal__Overlay .ReactModal__Content--after-open {
  max-height: 299px;
  max-width: 540px;
}
/* .estimate__modal .tabs{
    position: sticky;
    top: 0;
    background: #fff;
    z-index: 99;
} */
.max-h-image {
  max-height: 512px;
}

.bg-custom {
  background: #ebebeb;
}
.esimate__imageBlock.h-card {
  border-radius: 4px;
}
.esimate__imageBlock.h-card > div {
  height: 100%;
  width: 100%;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #fafafa;
}

::-webkit-scrollbar {
  width: 4px;
  background-color: #fff;
}

::-webkit-scrollbar-thumb {
  background-color: #888888;
}

/****Start Form Layout*******/

.right-side-form-wrap {
  @apply xl:px-14 xl:pl-16 flex flex-row justify-center;
}
.quote-form-warp .contact-form {
  @apply m-auto w-full mt-8 lg:mt-0 lg:w-2/3 2xl:w-3/4 bg-white flex flex-col float-right shadow-xl;
}
.quote-form-overlay .contact-form {
  @apply m-auto mt-8 lg:mt-0 w-full sm:w-3/4 lg:w-1/2 xl:w-2/5 bg-transparent flex flex-col;
}

.quote-form-overlay #contacts h3 {
  @apply tracking-tight 2xl:tracking-wide font-primaryMedium text-3xl xxs:text-4xl md:text-5xl text-white mb-7 mx-auto;
}

.right-side-form-wrap .quote-form-warp .contact-form {
  @apply w-full bg-white 2md:rounded-xl shadow-lg;
}

.quote-form-warp #contacts .get-quote-btn {
  @apply disabled:opacity-10 block p-3 w-full mt-2 rounded-full bg-primary shadow-lg text-white text-base 2xl:text-lg font-primaryRegular uppercase text-center;
}

.quote-form-overlay #contacts .get-quote-btn {
  @apply disabled:opacity-10 block p-3 w-full md:w-1/2 mx-auto bg-primary text-white text-sm 2xl:text-lg xxs:text-lg font-primaryRegular uppercase text-center mb-4;
}

.quote-form-warp #contacts .average-bill-select {
  @apply xxs:mb-0 mb-3 rounded-md appearance-none text-sm xxs:text-base 2xl:text-lg focus:outline-none pl-12 pr-3 p-3 border border-gray-300 w-full placeholder-current text-bodyColor;
}

.quote-form-overlay #contacts .mb-7 {
  @apply mb-4;
}
.quote-form-overlay #contacts .my-7 {
  @apply my-4;
}

.quote-form-warp .desc-form {
  @apply font-primaryLight text-xs 2xl:text-xs text-gray-400 mt-4;
}
.quote-form-overlay .desc-form {
  margin-left: -2.2rem;
  margin-right: -2.2rem;
  @apply font-primaryLight text-xs 2xl:text-xs text-gray-400 mt-4 text-center;
}

/******End Form Layout******/

@media screen and (min-width: 1530px) {
  .main__heading {
    font-size: 51px;
    line-height: 50px;
  }
}

@media screen and (min-width: 768px) {
  .esimate__imageBlock.h-card {
    height: 500px;
  }
  .our-partner-section .image-filter:hover {
    -webkit-filter: none;
    filter: none;
  }
}
@media screen and (max-width: 767px) {
  .accordion__panel {
    padding: 20px 0 14px !important;
  }
  .h-17rem {
    height: auto;
  }
  .home__wrap {
    @apply min-h-full;
  }
  .esimate__imageBlock > div {
    height: 280px !important;
  }
  .approval_dialog.payment_overlay.AmountModal__Overlay
    .ReactModal__Content--after-open {
    min-width: 90vw;
    min-height: 100vh;
    max-width: 90%;
  }
  .approval_dialog.payment_overlay .maxHwight {
    max-height: 62vh;
    overflow: auto;
  }
  /* .our-partner-section .image-filter{
        filter: none;
    } */
  .our-partner-section .image-filter:focus,
  .our-partner-section .image-filter:active {
    -webkit-filter: none;
    filter: none;
  }
}
@media screen and (max-width: 480px) {
  .powerwall-image,
  .solar-image {
    margin: 0 auto;
  }
}
@media screen and (max-width: 1600px) {
  blue-strip {
    max-width: 100%;
  }
}

.form-error {
  color: red;
  font-size: 12px;
  line-height: 1px;
  position: relative;
}

rect {
  cursor: crosshair;
}

#map {
  margin: 0px;
  padding: 0px;
}

.overlay {
  margin: 0px;
  padding: 0px;
}

.panels-div {
  margin: 0px;
  padding: 0px;
  float: left;
  width: 110px;
  cursor: move;
}

.active > div:first-child {
  border: 1px solid var(--primary) !important;
  @apply rounded-md;
}

.selected {
  fill: var(--primary);
}

.tool-action-btn {
  background: #00000036;
  border: 0px;
  padding: 5px 4px;
  border: 0;
  margin: 0 2px;
  border-radius: 16px;
  font-size: 17px;
}

.panels-div > div:first-child {
  border: 1px solid transparent;
}

.panels-div > div:first-child:hover {
  border: 1px dashed var(--primary) !important;
}
button.tool-action-btn.top-left {
  top: 10px !important;
  left: 10px !important;
}
button.tool-action-btn.top-right {
  top: 10px !important;
}
button.tool-action-btn.bottom {
  bottom: 10px !important;
}
.bg-div {
  background: #fff;
  border: 0px;
  padding: 4px 7px;
  -webkit-box-shadow: 0 0 4px 4px rgb(0 0 0 / 30%);
  box-shadow: 0 0 4px 4px rgb(0 0 0 / 30%);
  margin: 0 2px;
  border-radius: 16px;
  font-size: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.top-right .bg-div {
  padding: 4px 5px;
}
.total-count {
  background: #fff;
  padding: 10px;
  font-size: 14px;
}
button.tool-action-btn.adjust {
  top: 0px !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  left: 0 !important;
}
button.tool-action-btn.adjust-done {
  top: 10px !important;
}
button.tool-action-btn.adjust-done .bg-div,
button.tool-action-btn.adjust .bg-div {
  font-size: 14px;
}
.tool-action-btn.top-left.add-panel .bg-div {
  background: var(--primary);
  border: 0;
  padding: 4px 7px;
  -webkit-box-shadow: 0 0 4px 4px rgb(0 0 0 / 30%);
  box-shadow: 0 0 4px 4px rgb(0 0 0 / 30%);
  margin: 0 2px;
  border-radius: 16px;
  font-size: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fff;
}
.form-control-overlay {
  z-index: 1001 !important;
}
.EstimateDetailModal__Html--open .reactour__helper--is-open {
  display: none;
}
.EstimateDetailModal__Html--open #___reactour .kWwJhA {
  display: none;
}

.form-control-overlay .ReactModal__Content--after-open {
  max-width: 820px;
  max-height: 430px;
  inset: 40px !important;
  border-radius: 6px !important;
  top: 50% !important;
  left: 50% !important;
  -webkit-transform: translate(-50%, -50%) !important;
  -ms-transform: translate(-50%, -50%) !important;
  transform: translate(-50%, -50%) !important;
  right: auto !important;
  bottom: auto !important;
  margin-right: -50% !important;
}
.EstimateDetailModalPortal
  .form-control-overlay
  .ReactModal__Content--after-open {
  max-height: inherit;
}
.EstimateDetailModalPortal
  .otp-dialog.form-control-overlay
  .ReactModal__Content--after-open {
  min-height: 340px;
  max-width: 100%;
  width: 92%;
  @screen sm {
    max-width: 520px;
  }
}
@media screen and (min-width: 640px) {
  .EstimateDetailModalPortal
    .otp-dialog.form-control-overlay
    .ReactModal__Content--after-open {
    max-width: 520px;
  }
}
.EstimateDetailModalPortal
  .alert-modal.form-control-overlay
  .ReactModal__Content--after-open {
  min-height: inherit;
  max-width: 520px;
  width: 92%;
  @screen md {
    width: 100%;
  }
}
.buy-contact-modal.form-control-overlay .ReactModal__Content--after-open {
  max-width: 820px;
  max-height: 430px;
  inset: 40px !important;
  border-radius: 8px !important;
  top: 50% !important;
  left: 50% !important;
  -webkit-transform: translate(-50%, -50%) !important;
  -ms-transform: translate(-50%, -50%) !important;
  transform: translate(-50%, -50%) !important;
  right: auto !important;
  bottom: auto !important;
  margin-right: -50% !important;
}
.buy-contact-modal.form-control-overlay .ReactModal__Content--after-open {
  min-width: auto;
  min-height: 420px;
}

.form-control-overlay.leadformModal .ReactModal__Content--after-open {
  max-width: 90%;
  max-height: 80vh;
  min-width: 40%;
  padding: 20px 0 !important;
}
@media screen and (max-width: 480px) {
  .form-control-overlay.leadformModal .ReactModal__Content--after-open {
    padding: 0 !important;
  }
}
.form-control-overlay.whatNext .ReactModal__Content--after-open {
  max-width: 80%;
  max-height: 540px;
}
.form-control-overlay.loanpalModal .ReactModal__Content--after-open {
  outline: none;
  padding: 0px 0 !important;
  overflow: hidden;
  max-width: 70%;
  min-width: 70%;
  min-height: 200px;
}
.form-control-overlay.loanpalModal
  .ReactModal__Content--after-open
  .ammount__modal {
  max-height: 80vh;
  min-height: 80vh;
  overflow: auto;
}

@media screen and (max-width: 768px) {
  .form-control-overlay.loanpalModal
    .ReactModal__Content--after-open
    .ammount__modal {
    min-height: 100vh;
    max-height: 100vh;
  }
}
@media screen and (min-width: 1600px) {
  .form-control-overlay.loanpalModal .ReactModal__Content--after-open {
    max-width: 1200px;
    min-width: auto;
  }
}
@media screen and (max-width: 480px) {
  /* .form-control-overlay.loanpalModal .ReactModal__Content--after-open,
  .form-control-overlay.loanpalModal
    .ReactModal__Content--after-open
    .ammount__modal {
    padding: 20px 10px !important;
  } */
}

.form-control-overlay.leadformModal.exampleModal
  .ReactModal__Content--after-open {
  max-width: 810px;
  max-height: 560px;
}

.form-control-overlay.leadformModal.loanResponse
  .ReactModal__Content--after-open {
  width: 44%;
  border-radius: 10px !important;
  @apply shadow-lg;
}
@media screen and (max-width: 768px) {
  .form-control-overlay.leadformModal.loanResponse
    .ReactModal__Content--after-open {
    width: 100%;
    height: 100%;
    border-radius: 0 !important;
    @apply shadow-none;
  }
}

@media screen and (max-width: 768px) {
  .form-control-overlay .ReactModal__Content--after-open {
    max-width: 100%;
    max-height: calc(var(--vh, 1vh) * 100);
    /* height: calc(var(--vh, 1vh) * 100); */
    /* width: 100vw; */
    border-radius: 0 !important;
  }
  .form-control-overlay.leadformModal .ReactModal__Content--after-open,
  .form-control-overlay.leadformModal.whatNext
    .ReactModal__Content--after-open {
    max-width: 100vw;
    max-height: calc(var(--vh, 1vh) * 100) !important;
  }
  .form-control-overlay.leadformModal.whatNext
    .ReactModal__Content--after-open {
    height: calc(var(--vh, 1vh) * 100) !important;
  }
  .form-control-overlay.loanpalModal .ReactModal__Content--after-open {
    max-width: 100%;
    min-width: 90%;
    max-height: calc(var(--vh, 1vh) * 100);
    min-height: calc(var(--vh, 1vh) * 100);
  }
}
.bg-dark-blue {
  background: #2f384d;
  border: 1px solid #2f384d;
}
.panels-19 {
  width: 53px;
}
.panels-18 {
  width: 27px;
}
.panels-17 {
  width: 16px;
}
.panels-16 {
  width: 8px;
}
.panels-15 {
  width: 4px;
}
.panels-14 {
  width: 2px;
}
.panels-13 {
  width: 0px;
}
.z-999 {
  z-index: 999;
}

/* //liggt */
/*  */
/* body */

/* .light {
  --primary: var(--primary);
  --other: hsl(0, 0%, 14%);
} */

/* label */
.checkbox-custom label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  cursor: pointer;
  position: relative;
}

/* input */
.checkbox-custom input {
  opacity: 0;
  position: relative;
  z-index: -1;
}

/* .design */
.checkbox-custom .design {
  width: 25px;
  height: 25px;

  border: 1px solid var(--other);
  border-radius: 100%;
  margin-right: 10px;

  position: relative;
}

.checkbox-custom .design::before,
.checkbox-custom .design::after {
  content: "";
  display: block;

  width: inherit;
  height: inherit;

  border-radius: inherit;

  position: absolute;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transform-origin: center center;
  -ms-transform-origin: center center;
  transform-origin: center center;
}

.checkbox-custom .design:before {
  background: var(--other);
  opacity: 0;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  top: -1px;
  left: -1px;
}

.checkbox-custom .checked .design::after {
  background: var(--primary);
  /* opacity: .4; */
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  -webkit-transform: scale(0.5);
  -ms-transform: scale(0.5);
  transform: scale(0.5);
  top: -1px;
  left: -1px;
}

.checkbox-custom input {
  height: 22px;
  width: 22px;
}

/* checked state */
/* .checkbox-custom .checked input:checked+.design::before {
    opacity: 1;
    transform: scale(.5);
    top: -1px;
    left: -1px;
    background: var(--primary);
  } */
.checkbox-custom .checked .design {
  border: 1px solid var(--primary);
}

/* other states */
.checkbox-custom input:hover + .design,
.checkbox-custom input:focus + .design {
  border: 1px solid var(--primary);
}

.checkbox-custom input:hover + .design:before,
.checkbox-custom input:focus + .design:before {
  background: var(--primary);
}

.checkbox-custom input:hover ~ .text {
  color: var(--primary);
}

.accordion__button:before {
  display: inline-block;
  content: "";
  height: 10px;
  width: 10px;
  margin-right: 12px;
  border-bottom: 2px solid currentColor;
  border-right: 2px solid currentColor;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  position: absolute;
  right: 0px;
  margin-top: 10px;
}

.accordion__button[aria-expanded="true"]::before,
.accordion__button[aria-selected="true"]::before {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

[hidden] {
  display: none;
}

.accordion__panel {
  padding: 20px 0 14px;
  -webkit-animation: fadein 280ms cubic-bezier(0.4, 0, 0.2, 1);
  animation: fadein 280ms cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition: 280ms cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition: 280ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: 280ms cubic-bezier(0.4, 0, 0.2, 1);
}

/* -------------------------------------------------- */
/* ---------------- Animation part ------------------ */
/* -------------------------------------------------- */

@-webkit-keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.accordion_sidebar {
  padding: 4px 12px 0px 12px !important;
}
.horizontal-slider {
  /* width: 100%; */
  /* max-width: 100%; */
  height: 50px;
  /* border: 1px solid grey; */
  width: 100%;
}
.vertical-slider {
  height: 380px;
  width: 50px;
  border: 1px solid grey;
}
.example-thumb {
  font-size: 0.9em;
  text-align: center;
  background-color: black;
  color: white;
  cursor: pointer;
  border: 5px solid gray;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.example-thumb.active {
  background-color: grey;
}
.example-track {
  position: relative;
  background: var(--primary);
}
.example-track.example-track-1 {
  background: #5b616f;
}
.example-track.example-track-2 {
  background: #0f0;
}
.example-mark {
  background-color: #fff;
  cursor: pointer;
  border-radius: 50%;
  width: 17px;
  height: 17px;
  border-radius: 9px;
  -webkit-filter: drop-shadow(0px 2px 2.5px rgba(0, 0, 0, 0.17));
  filter: drop-shadow(0px 2px 2.5px rgba(0, 0, 0, 0.17));
  background-color: #97a2b8;
  border: 3px solid #ffffff;
  background: transparent;
}
.horizontal-slider .example-track {
  top: 20px;
  height: 11px;
  border-radius: 10px;
}
.horizontal-slider .example-thumb {
  top: 6px;
  width: 60px;
  /* height: 48px; */
  line-height: 30px;
  /* width: 86px; */
  /* height: auto; */
  border-radius: 2px;
  -webkit-filter: drop-shadow(0px 4px 3.5px rgba(0, 0, 0, 0.06));
  filter: drop-shadow(0px 4px 3.5px rgba(0, 0, 0, 0.06));
  background-color: #ffffff;
  border: 2px solid #d9d9d9;
  font-size: 12px;
  /* line-height: 49px; */
  color: #5a5b5d;
  /* /* height: 32px; */
  padding: 0 3px;
}
.horizontal-slider .example-mark {
  margin: 0 calc(25px - 6px);
  bottom: calc(50% - 9px);
}
.vertical-slider .example-thumb {
  top: 6px;
  width: 50px;
  /* height: 48px; */
  line-height: 30px;
  /* width: 86px; */
  /* height: auto; */
  border-radius: 2px;
  -webkit-filter: drop-shadow(0px 4px 3.5px rgba(0, 0, 0, 0.06));
  filter: drop-shadow(0px 4px 3.5px rgba(0, 0, 0, 0.06));
  background-color: #ffffff;
  border: 2px solid #d9d9d9;
  font-size: 14px;
  /* line-height: 49px; */
  color: #5a5b5d;
  /* /* height: 32px; */
  padding: 0 3px;
}
.vertical-slider .example-track {
  left: 20px;
  width: 10px;
}
.vertical-slider .example-mark {
  margin: calc(25px - 6px) 0;
  left: calc(50% - 6px);
}
/* .horizontal-slider .example-mark:last-child {
    margin: 0 calc(39px - 6px);
 } */

/* .horizontal-slider .example-mark:first-child{
    margin: 0 calc(6px - 6px);
} */
/* .better-best .example-track.example-track-0 {
    left: 19px !important;
}
.better-best .example-track.example-track-1 {
    right: 15px !important;
} */
.better-best .example-track.example-track-0 {
  left: 2px !important;
  right: 18px !important;
}
.better-best .example-track.example-track-1 {
  right: 11px !important;
}
.better-best.horizontal-slider .example-thumb {
  top: 11px;
  width: 28px;
  height: 28px;
  line-height: 28px;
  /* width: 86px; */
  /* height: auto; */
  /* border-radius: 2px; */
  /* filter: drop-shadow(0px 4px 3.5px rgba(0,0,0,0.06)); */
  /* background-color: #ffffff; */
  border: 4px solid #fff;
  font-size: 15px;
  /* line-height: 49px; */
  color: #5a5b5d;
  border-radius: 100%;
  width: 25px;
  height: 25px;
  border-radius: 16px;
  -webkit-filter: drop-shadow(0px 2px 2.5px rgba(0, 0, 0, 0.21));
  filter: drop-shadow(0px 2px 2.5px rgba(0, 0, 0, 0.21));
  background-color: var(--primary);
  padding: 6px;
  margin-left: 0px;
}
.better-best.horizontal-slider .example-thumb:focus {
  outline: none;
}
.better-best .example-mark {
  background-color: #fff;
  cursor: pointer;
  border-radius: 50%;
  width: 17px;
  height: 17px;
  border-radius: 9px;
  -webkit-filter: drop-shadow(0px 2px 2.5px rgba(0, 0, 0, 0.17));
  filter: drop-shadow(0px 2px 2.5px rgba(0, 0, 0, 0.17));
  background-color: #97a2b8;
  border: 3px solid #ffffff;
  background: transparent;
}
.better-best.horizontal-slider .example-mark {
  margin: 0 0 0 0px;
  bottom: calc(50% - 9px);
}
.border-inside {
  @apply h-11  border border-gray-200 rounded bg-gray-100 xl:w-4/5 w-full focus:outline-none text-bodyColor text-sm;
}
.max-w-48 {
  max-width: 100%;
}
@screen 2md {
  .max-w-48 {
    max-width: 490px;
  }
}
.shadow-lg-g {
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 44%),
    0 4px 6px -2px rgb(0 0 0 / 43%);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.select-box-wrap .css-1okebmr-indicatorSeparator {
  display: none;
}
.select-box-wrap .css-yk16xz-control > div:last-child,
.select-box-wrap .css-1pahdxg-control > div:last-child {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.inputStyle {
  width: 3rem !important;
  height: 3rem;
  margin: 0 1rem;
  font-size: 1.3rem;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
}
.otp-dialog.form-control-overlay .ReactModal__Content--after-open {
  min-width: 460px;
  min-height: 420px;
}

@media screen and (max-width: 768px) {
  .otp-dialog.form-control-overlay .ReactModal__Content--after-open {
    min-width: auto;
    min-height: auto;
  }
}
.active-solarpanel {
  border-radius: 5px;
  -webkit-filter: drop-shadow(0px 7px 10.5px rgba(56, 56, 56, 0.13));
  filter: drop-shadow(0px 7px 10.5px rgba(56, 56, 56, 0.13));
}
.accordion__panel .tabs > div {
  @apply xxs:mr-4;
}
.height-custom {
  height: 210px;
}
.height-custom img {
  max-height: 100%;
}
.thnakyou {
  min-height: calc(100vh - 6rem);
}
@media screen and (min-width: 1530px) {
  .main__heading {
    font-size: 51px;
    line-height: 50px;
  }
}
.landing-v2 {
  @apply bg-titleColor;
  /* background: url('../public/images/page-banner.png'); */
}
/* .landing-v3 {
  background: url("../public/images/page-banner.png");
  background-size: cover;
  background-position: center;
} */
/* page 1 banner */
.landing-v1 {
  background: url("../public/images/home/home-banner-bg.jpeg");
  background-size: cover;
  background-position: center;
}

.landing-solar1 {
  background: url("../public/images/bg/solar-bg1.jpg");
  background-size: cover;
  background-position: center;
  height: 70vh;
}
.landing-solar2 {
  background: url("../public/images/bg/solar-bg1.jpg");
  background-size: cover;
  background-position: center;
}
.landing-mission-solar {
  background: url("../public/images/bg/bannertype1.jpeg");
  background-size: cover;
  background-position: center;
}
.contact-form-bg {
  background: url("../public/images/contact-form-img.png");
  background-size: cover;
  background-position: center;
}
.contact-solar {
  background: url("../public/images/bg/get-solar-battery-bg.jpeg");
  background-size: cover;
  background-position: center;
}
.solar-page {
  background: url("../public/images/bg/solar-banner.jpeg");
  background-size: cover;
  background-position: center;
  height: 480px;
}

.battery-banner {
  background: url("../public/images/bg/battery-banner.jpeg");
  background-size: cover;
  background-position: center;
  height: 480px;
}
.charger-banner {
  background: url("../public/images/bg/charger-banner.jpg");
  background-size: cover;
  background-position: center;
  height: 480px;
}
.why-solar-banner {
  background: url("../public/images/bg/why-solar-banner-bg.jpeg");
  background-size: cover;
  background-position: center;
  height: 480px;
}
.company-banner {
  background: url("../public/images/bg/company-banner.jpeg");
  background-size: cover;
  background-position: center;
  height: 480px;
}

.resource-banner {
  background: url("../public/images/bg/banner.jpg");
  background-size: cover;
  background-position: center;
  height: 360px;
}
.blog-banner {
  background: url("../public/images/bg/blog-bg.jpg");
  background-size: cover;
  background-position: center;
  height: 180px;
}
.landing-v3 {
  /* background: url('../public/images/page-banner.png'); */
  background: url("../public/images/bg/support-banner.jpg");
  background-size: cover;
  background-position: center;
  height: 480px;
}
.referral-banner {
  background: url("../public/images/referral-page/referral-banner.jpg");
  background-size: cover;
  background-position: center;
  height: 680px;
}
.referral-banner2 {
  background: url("../public/images/referral-page/referral-banner2.jpg");
  background-size: cover;
  background-position: center;
  height: 100%;
}
.referral-banner-notUser {
  background: url("../public/images/referral-page/referral-banner.jpg");
  background-size: cover;
  background-position: center;
  height: 100%;
}
.clientOrg.referral-banner-notUser {
  background-size: 100% 30%;
  background-position: top;
  height: auto;
  background-repeat: no-repeat;
  @screen md {
    background-size: 100% 50%;
    background-position: top;
    height: auto;
    background-repeat: no-repeat;
  }

  @screen lg {
    background-size: cover;
    background-position: center;
    height: 100%;
  }
}

@media screen and (min-width: 320px) {
  .clientOrg.referral-banner-notUser.bg-layer-blue:before {
    height: 30%;
  }
}
@media screen and (min-width: 767px) {
  .clientOrg.referral-banner-notUser.bg-layer-blue:before {
    height: 50%;
  }
}
@media screen and (min-width: 1024px) {
  .clientOrg.referral-banner-notUser.bg-layer-blue:before {
    height: 100%;
  }
}

.reference-program-bg {
  background: url("../public/images/referral-page/reference-graphics.png");
  background-size: contain;
  background-position: center;
  height: 100%;
  background-repeat: no-repeat;
}
.armando-banner {
  background: url("../public/images/bg/referral-banner.jpg");
  background-size: cover;
  background-position: center;
  height: 480px;
}
.consultation {
  background: url("../public/images/bg/online-consultation.jpeg");
  background-size: cover;
  background-position: center;
  height: 480px;
}
.designs {
  background: url("../public/images/bg/design.jpeg");
  background-size: cover;
  background-position: center;
  height: 480px;
}
.permit {
  background: url("../public/images/bg/permitting.jpeg");
  background-size: cover;
  background-position: center;
  height: 480px;
}
.install {
  background: url("../public/images/bg/installation.jpeg");
  background-size: cover;
  background-position: center;
  height: 480px;
}
.activation {
  background: url("../public/images/bg/activation.jpeg");
  background-size: cover;
  background-position: center;
  height: 480px;
}
.image-filter {
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
}
/* .banner-height {
  min-height: calc(100vh - 6rem);
} */
.landing-v3.support-banner {
  height: 280px;
  background: url("../public/images/bg/get-in-touch.jpeg");
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}

@media screen and (max-width: 767px) {
  .landing-solar1 {
    height: 100vh;
  }
}
.line-height {
  line-height: 1.6rem;
}
.line-height2 {
  line-height: 1.2rem;
}

.max-w--xxs {
  max-width: 160px;
}
.info-data {
  display: none;
}
.info-box .info-data {
  min-width: 250px;
  @apply shadow-md py-2 px-3 text-white font-primaryRegular text-sm bg-black bg-opacity-75 top-4 rounded;
}
.info-box:hover .info-data {
  @apply z-10 block  absolute left-2 w-auto right-4  top-4;
}
.info-box-h {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.solar-sepci ui li {
  position: relative;
}
.flag-btn {
  height: 31px;
  border-radius: 2px;
  background-color: #fcfcfc;
  border: 1px solid #999999;
}
.react-multiple-carousel__arrow::before {
  color: #2f384d;
}
.react-multiple-carousel__arrow {
  border-radius: 4px;
  background: #fff;
  -webkit-box-shadow: 1px 2px 3px #ccc;
  box-shadow: 1px 2px 3px #ccc;
}
.react-multiple-carousel__arrow--right {
  right: 3px;
  z-index: 1;
}
.react-multiple-carousel__arrow--left {
  left: 3px;
  z-index: 1;
}
@media screen and (max-width: 640px) {
  .landing-v1 {
    background: url("../public/images/banner-mobile.jpg");
    background-size: cover;
    background-position: right -214px;
  }
  .unsupported.landing-v1 {
    background-position: right -4px;
  }
  .banner-mob-height {
    height: 340px;
  }
  .blog-banner {
    height: 150px;
  }
  div.blog-form-wrap {
    min-height: inherit;
  }
  .support-banner {
    height: 340px;
  }
}
@media screen and (max-width: 480px) {
  .solar-sepci .react-multiple-carousel__arrow--right,
  .battery-Comparsion .react-multiple-carousel__arrow--right {
    right: 2px;
    top: 120px;
  }
  .solar-sepci .react-multiple-carousel__arrow--left,
  .battery-Comparsion .react-multiple-carousel__arrow--left {
    left: 3px;
    top: 120px;
  }
}
.react-multiple-carousel__arrow:hover {
  background: rgb(243 147 61);
}
.react-multiple-carousel__arrow:hover::before {
  color: #fff;
}

/*----------------------- self-site-survey css -------------------------*/
.scroll-bar-site-surey::-webkit-scrollbar {
  display: block;
}
.scroll-bar-site-surey::-webkit-scrollbar {
  width: 8px;
  background-color: #fff;
}
.scroll-bar-site-surey::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #fafafa;
  border-radius: 4px;
}

.scroll-bar-site-surey::-webkit-scrollbar-thumb {
  background-color: #cac9c9;
  border-radius: 4px;
}

@media (min-width: 0px) {
  .selected-image-of-site-survey {
    min-height: 190px;
  }
}
@media (min-width: 480px) {
  .selected-image-of-site-survey {
    min-height: 300px;
  }
}
@media (min-width: 1280px) {
  .selected-image-of-site-survey {
    min-height: 320px;
  }
}

/* ----------------------------------------------------------------------- */

.carousel-container-site-survey .react-multiple-carousel__arrow {
  position: absolute;
  outline: 0;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  border-radius: 35px;
  /* z-index: 99; */
  border: 0;
  min-width: 35px;
  min-height: 35px;
  opacity: 1;
  cursor: pointer;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.carousel-container-site-survey .react-multiple-carousel__arrow::before {
  font-size: 15px;
}
.carousel-container-site-survey .react-multi-carousel-dot--active button {
  background: var(--primary);
}

.file-upload-wrapper-p .form {
  width: 100%;
}

.file-upload-wrapper {
  height: 46px;
  position: relative;
  width: 100%;
}
.file-upload-wrapper:before {
  content: "Upload";
  position: absolute;
  top: 0;
  right: 0;
  display: inline-block;
  height: auto;
  color: #fff;
  font-weight: 700;
  z-index: 25;
  font-size: 16px;
  /* line-height: 40px; */
  padding: 11px 15px;
  text-transform: uppercase;
  pointer-events: none;
  border-radius: 0 5px 5px 0;
  background: #7a7b7c;
  /* pointer-events: none; */
}
.file-upload-wrapper:after {
  content: attr(data-text);
  font-size: 18px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  padding: 8.5px 15px;
  display: block;
  width: calc(100% - 40px);
  pointer-events: none;
  z-index: 20;
  height: auto;
  /* line-height: 40px; */
  color: #999;
  border-radius: 5px 10px 10px 5px;
  font-weight: 300;
  border: 1px solid #ccc;
}
.file-upload-wrapper input {
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  height: 55px;
  margin: 0;
  padding: 0;
  display: block;
  cursor: pointer;
  width: 100%;
}
@media screen and (max-width: 480px) {
  .file-upload-wrapper:before {
    font-size: 16px;
    /* line-height: 40px; */
    padding: 7px 15px;
  }
  .file-upload-wrapper:after {
    font-size: 13px;
    padding: 8.5px 15px;
  }
}
@media screen and (max-width: 1600px) {
  blue-strip {
    max-width: 100%;
  }
}

/* ==================loader================ */
.spinner {
  -webkit-animation: frames 1s infinite linear;
  animation: frames 1s infinite linear;
  background: transparent;
  border: 5px solid #fff;
  border-radius: 100%;
  /* border-top-color: #df691a; */
  border-top-color: var(--primary);
  width: 50px;
  height: 50px;
  opacity: 0.6;
  padding: 0;
  position: relative;
  z-index: 99;
  transition: all 3s;
}
@-webkit-keyframes frames {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes frames {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

.load {
  position: relative;
  width: 60px;
  height: 60px;
  margin: 100px 0;
  border-radius: 50%;
  border: 5px solid transparent;
  border-top: 5px solid var(--primary);
  border-bottom: 5px solid var(--primary);
  -webkit-animation: rotateAntiCW 1.8s linear infinite;
  animation: rotateAntiCW 1.8s linear infinite;
}

.load::after {
  content: "";
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 5px solid transparent;
  border-left: 5px solid #586070;
  border-right: 5px solid #586070;
  -webkit-animation: rotate 1s linear infinite;
  animation: rotate 1s linear infinite;
}

@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}

@-webkit-keyframes rotateAntiCW {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotateAntiCW {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

#pause {
  display: block;
  background: rgba(0, 0, 0, 0.66) no-repeat 0 0;
  width: 100%;
  height: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 999999 !important;
}
.border-3 {
  border-width: 3px;
}
/* .box-width{
    max-width: 370px;
  }
  @media (min-width: 1280px){
    .box-width{
        max-width: 440px;
      }
  }
  @media (min-width: 1536px){
    .box-width{
        max-width: 570px;
      }
  } */
  
.dropdown .dropdown-menu {
  width: 520px;
  -webkit-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-transition: all 0.25s ease-out;
  -o-transition: all 0.25s ease-out;
  transition: all 0.25s ease-out;
  -moz-transition: all 0.25s ease-out;
  -ms-transition: all 0.25s ease-out;
  -webkit-transform: rotateX(-90deg);
  -ms-transform: rotateX(-90deg);
  transform: rotateX(-90deg);
}
.dropdown .dropdown-menu.location-dropdown {
  width: 460px;
}

.dropdown:hover .dropdown-menu {
  display: block;
  -webkit-transform: rotateX(0);
  -ms-transform: rotateX(0);
  transform: rotateX(0);
}
.Toastify__toast-container {
  z-index: 999999 !important;
  width: 540px !important;
}
.Toastify__toast--error,
.Toastify__toast--success {
  background: #fff !important;
  color: #454340 !important;
  font-family: "ProximaNova-Light" !important;
}
.Toastify__progress-bar.Toastify__progress-bar--error {
  background-color: rgb(229 46 38) !important;
}
.Toastify__progress-bar--success.Toastify__progress-bar {
  background-color: rgb(88 189 47) !important;
}
.Toastify__close-button {
  color: #1c222e !important;
}
.bg-offwhite {
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  -webkit-filter: drop-shadow(0px -1px 4.5px rgba(0, 0, 0, 0.17));
  filter: drop-shadow(0px -1px 4.5px rgba(0, 0, 0, 0.17));
}
.bg-offwhite {
  @apply md:px-16 xl:px-16 py-4 z-999;
}

.bg-offwhite.js-is-sticky-header {
  @apply rounded py-4 px-5  z-999 mb-4;
}
.offwhite {
  background: #fbfbfb;
}
.number-selected {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background-color: #ffffff;
  border: 2px solid var(--primary);
}
.number-compelted {
  width: 25px;
  height: 25px;
  border-radius: 100%;
  background-color: var(--primary);
  border: 2px solid transparent;
}
.number-uncompelted {
  width: 25px;
  height: 25px;
  border-radius: 100%;
  background-color: #2e384d;
  opacity: 0.6;
  border: 2px solid transparent;
}
.number-incompelted {
  width: 25px;
  height: 25px;
  border-radius: 100%;
  background-color: #e8ecf1;
  opacity: 0.8;
  border: 2px solid transparent;
}
.number-pending {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  border: 2px solid transparent;
}
@media screen and (max-width: 480px) {
  .number-selected {
    width: 22px;
    height: 22px;
  }
  .number-compelted {
    width: 22px;
    height: 22px;
  }
  .number-uncompelted {
    width: 22px;
    height: 22px;
  }
  .number-pending {
    width: 22px;
    height: 22px;
  }
}

.rad-input {
  position: absolute;
  left: 0;
  top: 0;
  width: 1px;
  height: 1px;
  opacity: 0;
  z-index: -1;
}

.rad-design {
  width: 22px;
  height: 22px;
  border-radius: 100px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: 1px solid #1c222e;
}

.checked .rad-design {
  border: 1px solid var(--primary);
}

/* .rad-design span {
    display: none;
    transition: .3s;
}
.checked .rad-design span {
    display: block;
    height: 10px;
    width: 10px;
    background: var(--primary);
    border-radius: 100%;
    transition: .3s;
} */
.rad-design span {
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  opacity: 0;
  height: 10px;
  width: 10px;
}
.checked .rad-design span {
  background: var(--primary);
  border-radius: 100%;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}

.myElement {
  position: sticky;
  top: -1px;
}

/* styles for when the header is in sticky mode */
.myElement.is-pinned {
  color: red;
}

.checkbox-container {
  position: relative;
  pointer-events: none;
}
.checkbox-events {
  pointer-events: all !important;
}

.checkbox-container input {
  /* position: absolute; */
  opacity: 0;
  /* height: 0;
    width: 0; */
}

.checkbox-container .check {
  position: absolute;
  top: -10px;
  left: -6px;
  width: 22px;
  height: 22px;
  background: #fff;
  cursor: pointer;
  border-radius: 3px;
  border: 1px solid rgb(224, 224, 224);
  -webkit-box-shadow: 0px 0 1px #d0caca;
  box-shadow: 0px 0 1px #d0caca;
}
@media screen and (max-width: 768px) {
  .checkbox-container .check {
    position: absolute;
    top: 0;
    left: 0;
    width: 18px;
    height: 18px;
    background: #fff;
    cursor: pointer;
    border-radius: 3px;
    border: 1px solid rgb(224, 224, 224);
    -webkit-box-shadow: 0px 0 1px #d0caca;
    box-shadow: 0px 0 1px #d0caca;
  }
}

.checkbox-container .check:hover {
  background: #bbb;
}

.checkbox-container input:checked ~ .check {
  background: var(--primary);
  border: 1px solid var(--primary) !important;
}

.checkbox-container .check::after {
  position: absolute;
  content: "";
  left: 6.5px;
  top: 2px;
  width: 7px;
  height: 12px;
  border-color: #fff;
  border-style: solid;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg) scale(0.5);
  -ms-transform: rotate(45deg) scale(0.5);
  transform: rotate(45deg) scale(0.5);
  opacity: 0;
  -webkit-transition: -webkit-transform 0.1s linear;
  transition: -webkit-transform 0.1s linear;
  -o-transition: transform 0.1s linear;
  transition: transform 0.1s linear;
  transition: transform 0.1s linear, -webkit-transform 0.1s linear;
}
@media screen and (max-width: 768px) {
  .checkbox-container .check::after {
    position: absolute;
    content: "";
    left: 6.5px;
    top: 2px;
    width: 4px;
    height: 10px;
    border-color: #fff;
    border-style: solid;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg) scale(0.5);
    -ms-transform: rotate(45deg) scale(0.5);
    transform: rotate(45deg) scale(0.5);
    opacity: 0;
    -webkit-transition: -webkit-transform 0.1s linear;
    transition: -webkit-transform 0.1s linear;
    -o-transition: transform 0.1s linear;
    transition: transform 0.1s linear;
    transition: transform 0.1s linear, -webkit-transform 0.1s linear;
  }
}

.checkbox-container input:checked ~ .check::after {
  -webkit-transition: -webkit-transform 0.1s linear;
  transition: -webkit-transform 0.1s linear;
  -o-transition: transform 0.1s linear;
  transition: transform 0.1s linear;
  transition: transform 0.1s linear, -webkit-transform 0.1s linear;
  opacity: 1;
  -webkit-transform: rotate(45deg) scale(1);
  -ms-transform: rotate(45deg) scale(1);
  transform: rotate(45deg) scale(1);
}

.header {
  padding: 80px;
  text-align: center;
  color: white;
  background-image: url("/images/head-img.jpg");
  background-size: cover;
}

.header h1 {
  font-size: 40px;
}

.navbar {
  overflow: hidden;
  background: #269faf;
}

.navbar a {
  float: left;
  display: block;
  color: white;
  text-align: center;
  padding: 14px 20px;
  text-decoration: none;
}

.navbar a.right {
  float: right;
}

.navbar a:hover {
  background-color: #ddd;
  color: black;
}

.row {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
}

.side {
  -ms-flex: 30%;
  -webkit-box-flex: 30%;
  flex: 30%;
  background-color: #f1f1f1;
  padding: 20px;
}

.main {
  -ms-flex: 70%;
  -webkit-box-flex: 70%;
  flex: 70%;
  background-color: white;
  padding: 20px;
}

.fakeimg {
  width: 100%;
  max-height: 300px;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 4px;
}

.footer {
  padding: 20px;
  text-align: center;
  background: #ddd;
}

.sticky-header {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #ffffff;
  /* transition: all 0.5s ease; */
  -webkit-animation: smoothScroll 0.5s forwards;
  animation: smoothScroll 0.5s forwards;
}

/* .animation-custom{
  
    animation: smoothScroll 1s forwards;
  } */

@-webkit-keyframes smoothScroll {
  0% {
    -webkit-transform: translateY(-142px);
    transform: translateY(-142px);
  }

  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

@keyframes smoothScroll {
  0% {
    -webkit-transform: translateY(-142px);
    transform: translateY(-142px);
  }

  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

.fixedd {
  /* background:rgba(255, 255, 255, 0.842); */
  --tw-bg-opacity: 1;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  position: sticky;
  z-index: 100;
  -webkit-filter: drop-shadow(0px -1px 4.5px rgba(0, 0, 0, 0.17));
  filter: drop-shadow(0px -1px 4.5px rgba(0, 0, 0, 0.17));
}
.fixedd .offwhite {
  background: #fff;
}

/* .sticky-header{
    position: sticky;
    top: 0;
} */

/* .no-fixed{
    background:rgba(229, 231, 235, var(--tw-bg-opacity));
    padding: 8px;
  } */

.payment_dta .form-radio {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
  display: inline-block;
  vertical-align: middle;
  background-origin: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  border-radius: 100%;
  border-width: 2px;
}

.payment_dta .form-radio:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
  border-color: transparent;
  background-color: currentColor;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}

@media not print {
  .payment_dta .form-radio::-ms-check {
    border-width: 1px;
    color: transparent;
    background: inherit;
    border-color: inherit;
    border-radius: inherit;
  }
}

.payment_dta .form-radio:focus {
  outline: none;
}

.payment_dta .form-select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23a0aec0'%3e%3cpath d='M15.3 9.3a1 1 0 0 1 1.4 1.4l-4 4a1 1 0 0 1-1.4 0l-4-4a1 1 0 0 1 1.4-1.4l3.3 3.29 3.3-3.3z'/%3e%3c/svg%3e");
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
  background-repeat: no-repeat;
  padding-top: 0.5rem;
  padding-right: 2.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  background-position: right 0.5rem center;
  background-size: 1.5em 1.5em;
}

.payment_dta .form-select::-ms-expand {
  color: #a0aec0;
  border: none;
}

@media not print {
  .payment_dta .form-select::-ms-expand {
    display: none;
  }
}

@media print and (-ms-high-contrast: active),
  print and (-ms-high-contrast: none) {
  .payment_dta .form-select {
    padding-right: 0.75rem;
  }
}

.box-height {
  height: calc(100% - 1.1rem);
}
.AmountModal__Overlay.recomdation-battery .ReactModal__Content--after-open {
  max-width: 60vw;
  max-height: 100%;
  min-width: 800px;
  margin-top: 20px;
}

.fill-none {
  fill: none !important;
}
.transform-style-none {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  z-index: 1000;
  background-color: #fff;
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.transform-style {
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  -webkit-box-shadow: 0 0 65px rgba(0, 0, 0, 0.07);
  box-shadow: 0 0 65px rgba(0, 0, 0, 0.07);
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
@media screen and (max-width: 767px) {
  .Toastify__toast-container {
    max-width: 540px !important;
    width: auto !important;
  }
  .inputStyle {
    width: 2.5rem !important;
    height: 2.5rem;
  }
}
@media screen and (max-width: 980px) {
  .AmountModal__Overlay.recomdation-battery .ReactModal__Content--after-open {
    min-width: 80%;
  }
  .landing-banner-wrap {
    padding-bottom: 1rem;
  }
}

.Toastify__toast {
  width: auto;
}
@media screen and (max-width: 760px) {
  .Toastify__toast {
    width: 100vw;
  }
}
.menu-down {
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  -webkit-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
  opacity: 0;
  height: 0;
  pointer-events: none;
}
.menu-up {
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  -webkit-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
  /* opacity: 0;
    height: 0; */
}
.cutom-width {
  max-width: 150px;
}
.fixedd .grid-cols-3.bg-gray-200.p-2 {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  padding: 0.875rem;
}
.z-99999 {
  z-index: 99999 !important;
}

/* ================================================ */
.App {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100vh;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#fdfcfb),
    to(#e2d1c3)
  );
  background: -o-linear-gradient(#fdfcfb, #e2d1c3);
  background: linear-gradient(#fdfcfb, #e2d1c3);
  padding-top: 120px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

/* .search {
    position: relative;
  }
  
  .search button,
  .search span {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .search > * > svg {
    display: block;
  }
  
  .search span {
    left: 20px;
    opacity: .5;
    margin-top: 1px;
  }
  
  .search button {
    right: 20px;
    left: auto;
    border: none;
    margin: 0;
    padding: 0;
    background: none;
    cursor: pointer;
    color: #333;
  }
  
  .search button:hover {
    color: #f48fb1;
  }
  
  .search input {
    box-sizing: border-box;
    display: block;
    width: 650px;
    height: 70px;
    border-radius: 5px;
    padding: 0 50px 0 45px;
    font-size: 20px;
    outline: none;
    border: 2px solid #f48fb1;
    background: white;
  }
  
  .address {
    margin-top: 30px;
    font-size: 24px;
  }
  
  .address p {
    margin-bottom: 15px;
    margin-top: 0;
  }
  
  .address p span {
    opacity: .8;
  } */
.custom-svg {
  color: #8a909c;
}

.pac-container {
  padding: 0 8px;
  padding-bottom: 20px;
  font-family: inherit;
  font-size: inherit;
}
.pac-item {
  padding: 8px;
  -webkit-transition: 0.1s;
  -o-transition: 0.1s;
  transition: 0.1s;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}
.pac-item:hover {
  background: none;
  border-bottom: 1px solid var(--primary);
}
.custom-svg-white {
  color: #fff;
}
.font-15 {
  font-size: 15px;
}
@media screen and (max-width: 480px) {
  .font-15 {
    font-size: 14px;
  }
  .battery-banner {
    background-position: center left;
  }
}
.image-size {
  -o-object-fit: contain;
  object-fit: contain;
  max-width: 80%;
  max-height: 200px;
}
.image-size-soalr {
  -o-object-fit: contain;
  object-fit: contain;
  max-width: 80%;
}
.image-size-soalr-for-mobile {
  -o-object-fit: contain;
  object-fit: contain;
  max-width: 60%;
}

.logo-size {
  -o-object-fit: contain;
  object-fit: contain;
  max-width: 105px;
  @screen xxl {
    max-width: 114px;
  }
}
.spl__logo-size {
  -o-object-fit: contain;
  object-fit: contain;
  max-width: 110px;
  @screen xxl {
    max-width: 120px;
  }
}
.brand-logo-size {
  -o-object-fit: contain;
  object-fit: contain;
  max-width: 140px;
}
.contract-img {
  max-height: 120px;
  max-width: 100%;
}
.account-banner {
  background: url("../public/images/account-banner.png");
  background-size: cover;
  background-position: center;
  height: 356px;
}
@media screen and (max-width: 1024px) {
  .account-banner {
    height: 328px;
  }
}
@media screen and (max-width: 480px) {
  .account-banner {
    height: 200px;
  }
}
.contract-banner {
  background: url("../public/images/contractBanner.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 380px;
}
@media screen and (max-width: 800px) {
  .contract-banner {
    height: 250px;
  }
}
.account-bg {
  background: url("../public/images/account-bg.png");
  background-size: cover;
  background-position: center;
  height: 290px;
}
.account-bg-mobile {
  background-image: -o-linear-gradient(46deg, #1555a1 0%, #2293d5 100%);
  background-image: linear-gradient(44deg, #1555a1 0%, #2293d5 100%);
}

.bg-parallax-img {
  background: url("../public/images/why-solar/solar-estimate-bg.jpeg");
  background-size: cover;
  background-attachment: fixed;
}
section.bg-parallax-img:before {
  content: "";
  background: rgba(0, 0, 0, 0.1);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.react-tel-input .form-control {
  position: relative;
  padding: 24px;
  font-size: 14px;
  letter-spacing: 0.01rem;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 48px;
  margin-left: 0;
  background: #ffffff;
  border: 1px solid #cacaca;
  border-radius: 5px;
  line-height: 25px;
  height: 35px;
  width: 100% !important;
  outline: none;
}
.confirm-box {
  width: 680px;
}
@media screen and (max-width: 768px) {
  .confirm-box {
    width: 100%;
  }
}
.sq-overlay {
  pointer-events: none;
}
.img-box {
  height: 203px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.box-img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

@media screen and (max-width: 640px) {
  .account-bg {
    height: auto;
  }
}
.react-tel-input .form-control {
  position: relative;
  padding: 24px;
  font-size: 14px;
  letter-spacing: 0.01rem;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 48px;
  margin-left: 0;
  background: #ffffff;
  border: 1px solid #cacaca;
  border-radius: 5px;
  line-height: 25px;
  height: 35px;
  width: 100% !important;
  outline: none;
}
.confirm-box {
  width: 680px;
}
@media screen and (max-width: 768px) {
  .confirm-box {
    width: 100%;
  }
}
.sq-overlay {
  pointer-events: none;
}
.img-box {
  height: 203px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.box-img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

/* .premium-tag{
    position: absolute;
    top:0;
    left: 50px;
    z-index: 1;
    width: auto;
    height: auto;
    overflow: visible;
    text-decoration: none;
    transition: all 0.1s ease-in;
    pointer-events: none;
    will-change: transform;
    right: 50px;
    margin: auto;
}
  
.before-strip{
    border-top-left-radius: 15px;
   }
.after-strip{
    border-top-right-radius: 15px;
   }
    .premium-tag .strip{
        position: relative;
    top: -4px !important;
    z-index: 1;
    display: block;
    width: 160px;
    padding: 6px 0 6px 0;
    color: #ffffff;
    line-height: 1.2;
    text-align: center;
    text-decoration: none;
    background-color: #6e7279;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 20%);
    transition: all 0.1s ease-in;
    pointer-events: auto;
    transform: none;
    margin: auto;
    } */

/* .badge-overlay {
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        overflow: hidden;
        pointer-events: none;
        z-index: 1;
        -webkit-transition: width 1s ease, height 1s ease;
        -moz-transition: width 1s ease, height 1s ease;
        -o-transition: width 1s ease, height 1s ease;
        transition: width 0.4s ease, height 0.4s ease
    }
    
    .badge {
        margin: 0;
        padding: 0;
        color: white;
        text-transform: uppercase;
        padding: 6px 5px;
        font-size: 12px;
        font-family: 'ProximaNova-Medium', 'sans-serif';
        text-align: center;
        line-height: 14px;
        font-weight: bold;
        letter-spacing: 0.8px;
        background-image: linear-gradient( #4b7fbb,#1558a6);
    }
    
    .badge::before, .badge::after {
        content: '';
        position: absolute;
        top: 0;
        margin: 0 -1px;
        width: 100%;
        height: 100%;
        background: inherit;
        min-width: 55px
    }
    
    .badge::before {
        right: 100%
    }
    
    .badge::after {
        left: 100%
    }
    .top-right {
        position: absolute;
        top: 0;
        right: 0;
        -ms-transform: translateX(30%) translateY(0%) rotate(45deg);
        -webkit-transform: translateX(30%) translateY(0%) rotate(45deg);
        transform: translateX(30%) translateY(0%) rotate(45deg);
        -ms-transform-origin: top left;
        -webkit-transform-origin: top left;
        transform-origin: top left;
    } */

.ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
}
.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: 0;
  content: "";
  display: block;
  border: 7px solid #112c79;
}
.ribbon.isClientOrgCustomRib::before,
.ribbon.isClientOrgCustomRib::after {
  border: 7px solid var(--secondary);
}
.ribbon div {
  position: absolute;
  display: block;
  width: 225px;
  padding: 8px 0;
  background-color: #3758cf;
  color: #fff;
  font: 700 12px/1.2 "Lato", sans-serif;
  text-transform: uppercase;
  text-align: center;
}
.isClientOrgCustomRib.ribbon div {
  background-color: var(--secondary);
}
.solarScoutText div {
  color: var(--buttonText);
}
.ribbon-top-right {
  top: -10px;
  right: -10px;
}
.ribbon-top-right::before,
.ribbon-top-right::after {
  border-top-color: transparent;
  border-right-color: transparent;
}
.ribbon-top-right::before {
  top: -5px;
  left: 30px;
  border-top-left-radius: 24px;
}
.ribbon-top-right::after {
  bottom: 30px;
  right: -5px;
  border-bottom-right-radius: 24px;
}
.ribbon-top-right div {
  left: -5px;
  top: 20px;
  z-index: 1;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* RIBBON 2 */
.ribbon1 {
  width: auto;
  height: auto;
  position: absolute;
}
.ribbon1::before,
.ribbon1::after {
  position: absolute;
  z-index: 0;
  content: "";
  display: block;
  border: 8px solid #112c79;
}
.isClientOrgRibbonTopRight1.ribbon1::before,
.isClientOrgRibbonTopRight1.ribbon1::after {
  border: 8px solid var(--secondary);
  opacity: 0.95;
}
.ribbon1 div {
  display: block;
  width: auto;
  padding: 8px 0;
  background-color: #3758cf;
  color: #fff;
  font: 700 12px/1.2 "Lato", sans-serif;
  text-transform: uppercase;
  text-align: center;
  z-index: 1;
}
.isClientOrgRibbonTopRight1.ribbon1 div {
  background-color: var(--secondary);
}
.ribbon-top-right1 {
  top: -8px;
  left: -9px;
  right: -9px;
}
.ribbon-top-right1::before,
.ribbon-top-right1::after {
  border-top-color: transparent;
  border-right-color: transparent;
  z-index: -1;
}
.ribbon-top-right1::before {
  position: absolute;
  bottom: -8px;
  left: 0px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  border-bottom-left-radius: 8px;
}
.ribbon-top-right1::after {
  position: absolute;
  bottom: -8px;
  right: 0px;
  -webkit-transform: rotate(223deg);
  -ms-transform: rotate(223deg);
  transform: rotate(223deg);
  border-bottom-left-radius: 8px;
}

.radio-border {
  border-width: 6px;
}
.radio-border2 {
  border-width: 4px;
}
.img-box-example {
  height: 203px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
}
.dialog-img {
  height: 32vh;
  width: 100vw;
  -o-object-fit: cover;
  object-fit: cover;
}
.self-site-survey .ReactModal__Content.ReactModal__Content--after-open {
  width: 90%;
  overflow-y: auto !important;
}

.dropdown-menu .sub-menu a {
  @apply relative;
  -webkit-transition: left 0.2s ease-in;
  -o-transition: left 0.2s ease-in;
  transition: left 0.2s ease-in;
  left: 0;
}

.dropdown-menu .sub-menu:hover a {
  left: 8px;
  -webkit-transition: left 0.2s ease-in-out;
  -o-transition: left 0.2s ease-in-out;
  transition: left 0.2s ease-in-out;
}
.video-container {
  @apply flex items-center justify-center;
  min-height: 292px;
}

@media screen and (max-width: 768px) {
  .self-site-survey .ReactModal__Content.ReactModal__Content--after-open {
    width: 100%;
  }
  .video-container-iframe iframe {
    height: 200px !important;
  }
  .video-container-what-next iframe {
    height: 200px !important;
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
.leadForm .ReactModal__Content.ReactModal__Content--after-open {
  width: 66%;
  overflow-y: auto !important;
  border-radius: 12px !important;
  padding: 0 !important;
  max-width: 1200px;
}
@media screen and (max-width: 768px) {
  .leadForm .ReactModal__Content.ReactModal__Content--after-open {
    width: 100%;
    border-radius: 0 !important;
    max-width: 100%;
  }

  .filter-none {
    -webkit-filter: none;
    filter: none;
  }
  .filter-gray {
    -webkit-filter: grayscale(1);
    filter: grayscale(1);
  }
}

.hide-drift-chat #drift-frame-chat,
.hide-drift-chat #drift-frame-controller {
  display: none !important;
}

.loan-palModal-section .title-heading {
  @apply mt-4 mb-4 text-2xl font-primaryBold text-titleColor;
}
.loan-palModal-section .false.whitespace-pre-line {
  margin-bottom: 16px;
  font-size: 18px;
}

.whitespace-pre-line {
  white-space: pre-line;
}

.final-loan-section-min-h {
  min-height: 220px;
}

.English-notice p:first-child,
.spanish-notice p:first-child {
  @apply text-titleColor flex items-start;
  line-height: 2.25rem;
  font-weight: 600;
  font-family: sans-serif !important;
  /* color: #1c222e !important; */
  margin-top: 5px;
  margin-bottom: 20px;
}
/* @media screen and (max-width: 1024px){
    .English-notice p:first-child, .spanish-notice p:first-child {
        margin-top: 80px;
        }
} */
.English-notice p:first-child br,
.spanish-notice p:first-child br {
  display: none;
}
.English-notice p:nth-child(2),
.spanish-notice p:nth-child(2) {
  font-size: 1.1rem !important;
  font-family: sans-serif !important;
  margin-bottom: 10px;
  color: #636973;
}
/* .drift-conductor-item.drift-frame-controller.drift-frame-controller-align-right.drift-chat-open, .drift-conductor-item.drift-frame-chat.drift-frame-chat-align-right.with-transition.drift-chat-open, .drift-conductor-item.drift-frame-controller.drift-frame-controller-align-right.drift-has-chat{
    height: 80% !important;
} */
.tooltip {
  @apply invisible absolute;
}
.tooltip2 {
  @apply invisible absolute;
  top: -160px;
}

.has-tooltip:hover .tooltip,
.has-tooltip:hover .tooltip2 {
  @apply visible z-50;
}
.bg-orange-100 {
  background: #ffe7e7;
}
.bg-blue-light-100 {
  background: #d9e7ff;
}
.cus-w-h-24 {
  height: 6rem;
  width: 6rem;
}
.css-1u983ww-Mask {
  z-index: 9 !important;
}

/* NOTE: temp bg secondary color */
/* .bg-secondary {
  background: #0c3668;
  transition: all 0.3s ease-in-out;
} */

/* .bg-secondary:hover {
  background: #ff891b;
} */
.max-w-screen-md {
  max-width: 768px;
}

.react-multi-carousel-dot.react-multi-carousel-dot--active button {
  @apply bg-primary border-primary;
}
.react-multi-carousel-dot button:hover:active {
  @apply bg-primary border-primary;
}

/**blog css**/

.blog-detail-desc-wrap p {
  @apply text-base md:text-lg pb-2;
}
.blog-detail-desc-wrap h3,
.blog-detail-desc-wrap h2,
.blog-detail-desc-wrap h1 {
  @apply mt-3 pb-1 text-xl md:text-2xl font-configMedium;
}
.blog-detail-desc-wrap img {
  @apply mt-4 mb-3;
}
.blog-detail-desc-wrap a {
  @apply text-primary;
}
.blog-form-wrap {
  top: 10vh;
  min-height: 560px;
}
#servicetype {
  background-image: url("../public/images/select-arrow.png");
  background-repeat: no-repeat;
  background-size: 13px 13px;
  background-position: 96.5% 17px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

@media screen and (max-width: 439px) {
  .sm-device-hidden {
    @apply hidden;
  }
}
.blinking {
  display: inline-block;
  border: 3px solid var(--primary);
  -webkit-animation-name: blinking;
  animation-name: blinking;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}
@-webkit-keyframes blinking {
  50% {
    border-color: #ffefdf;
  }
}
@keyframes blinking {
  50% {
    border-color: #ffefdf;
  }
}
.blinking2 {
  border: 3px solid var(--primary);
  -webkit-animation-name: blinking2;
  animation-name: blinking2;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}
@-webkit-keyframes blinking2 {
  50% {
    border-color: #ffefdf;
  }
}
@keyframes blinking2 {
  50% {
    border-color: #ffefdf;
  }
}

.signatureHidden {
  height: 0 !important;
  overflow: hidden !important;
  visibility: hidden !important;
}

/* our Services */
.project-title-hover {
  position: absolute;
  bottom: 5.4rem;
}
.project-item .link-arrow {
  position: absolute;
  z-index: 2;
  color: #fff;
  left: 3rem;
  bottom: 3.3rem;
  -webkit-transition: none;
  -o-transition: none;
  transition: none;
}
.link-arrow {
  display: inline-block;
  line-height: 1 !important;
}

.project-item:hover .project-title-hover {
  display: none;
}

.project-item {
  position: relative;
  overflow: hidden;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.project-item img {
  width: 100%;
}
.item-shadow {
  position: relative;
}
.item-shadow:before {
  content: "";
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(46%, rgba(0, 0, 0, 0)),
    to(rgba(0, 0, 0, 0.6))
  );
  background: -o-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 46%,
    rgba(0, 0, 0, 0.6) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 46%,
    rgba(0, 0, 0, 0.6) 100%
  );
}
.project-item .link-arrow {
  position: absolute;
  z-index: 2;
  color: #fff;
  left: 3rem;
  bottom: 3.3rem;
  -webkit-transition: none;
  -o-transition: none;
  transition: none;
}
.project-hover {
  position: absolute;
  z-index: 2;
  left: 0;
  top: 0;
  width: 100.1%;
  height: 100%;
  padding: 1.85rem 3rem;
  -webkit-transition: 0.5s ease;
  -o-transition: 0.5s ease;
  transition: 0.5s ease;
}
.project-title {
  color: #fff;
  margin: 0;
}
.project-hover .project-title {
  position: absolute;
  bottom: 5.5rem;
  -webkit-transform: translateY(-20rem);
  -ms-transform: translateY(-20rem);
  transform: translateY(20rem);
}
.project-description {
  opacity: 0;
  margin-top: 10%;
  font-size: 1.125rem;
  line-height: 1.3;
  color: #333333;
  -webkit-transform: translateY(-20rem);
  -ms-transform: translateY(-20rem);
  transform: translateX(-20rem);
}
.project-item:hover .project-hover {
  background-color: rgba(255, 255, 255, 0.8);
}
.project-item .project-hover .project-title {
  position: relative;
  color: #5b5b5b;
  bottom: 0rem;
  font-size: 2.4rem;
  line-height: 1.25;
  margin-left: -0.11429em;
  -webkit-transform: translateY(-0.8em);
  -ms-transform: translateY(-0.8em);
  transform: translateY(-0.8em);
  opacity: 0;
  -webkit-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -webkit-transition-delay: 0.1s;
  -o-transition-delay: 0.1s;
  transition-delay: 0.1s;
  -webkit-transition: all 0.5s 0.1s;
  -o-transition: all 0.5s 0.1s;
  transition: all 0.5s 0.1s;
}
.project-item:hover .project-hover .project-title {
  -webkit-transform: translateY(0.8em);
  -ms-transform: translateY(0.8em);
  transform: translateY(0.8em);
  opacity: 1;
}
.project-item .project-hover .project-description {
  -webkit-transform: translateY(1.85714em);
  -ms-transform: translateY(1.85714em);
  transform: translateY(1.85714em);
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  opacity: 0;
}
.project-item:hover .project-hover .project-description {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
  -webkit-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -webkit-transition-delay: 0.1s;
  -o-transition-delay: 0.1s;
  transition-delay: 0.1s;
  -webkit-transition: all 0.5s 0.1s;
  -o-transition: all 0.5s 0.1s;
  transition: all 0.5s 0.1s;
}
.project-item:hover .link-arrow {
  color: #000;
  right: 2.42rem;
  bottom: 2.42rem;
  left: inherit;
  -webkit-transition: bottom 0.3s ease-out;
  -o-transition: bottom 0.3s ease-out;
  transition: bottom 0.3s ease-out;
}

@media screen and (max-width: 600px) {
  .project-item .project-hover .project-title {
    font-size: 1.875rem;
    line-height: 1.2;
  }
}
input:checked ~ .dot {
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%);
  background-color: white;
}
input:checked ~ .dot-bg {
  background-color: var(--primary);
  border: none;
}

.apply.selected {
  background-color: var(--primary);
}
.margin-top {
  margin-top: 24px !important;
}
.margin-top-10 {
  margin-top: 10px;
}
.popular-badge {
  font-size: 10px;
}
.mobile-estimate-bg {
  @apply bg-gray-50;
}

@media screen and (max-width: 360px) {
  .mobile-se-text {
    @apply text-base;
  }
}
.tabs_mobile {
  font-size: 18px !important;
}
.specs-height {
  height: 250px;
}
.blinking-dashed {
  border: 3px solid var(--primary);
  border-style: dashed;
  -webkit-animation-name: blinking2;
  animation-name: blinking2;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}
@keyframes blinking2 {
  50% {
    border-color: #ffefdf;
  }
}
.max-w-screen-1160 {
  max-width: 1160px;
}
.trusted-partner-carousel .react-multi-carousel-list {
  @apply pb-4;
}
.accordion .accordion__panel {
  @apply pt-3;
}

@media screen and (max-width: 640px) {
  .account-bg {
    height: auto;
  }
  .product-service-wrap .img-wrap img {
    max-height: 210px;
  }
  .battery-carousel-wrap .img-wrap img {
    max-height: 192px;
  }
  .company-carousel-wrap .img-wrap img {
    max-height: 222px;
  }
  .ribbon-top-right1 {
    top: -2px;
  }
}

@media screen and (max-width: 380px) {
  .home form h2.text-2xl {
    font-size: 1.35rem;
  }
}
@media screen and (max-width: 360px) {
  .home form h2.text-2xl {
    font-size: 1.25rem;
  }
  .mobile-se-text {
    @apply text-base;
  }
}
.height-54 {
  height: 54px;
  width: 52px;
}
/* slider for instant estimate */
.horizontal-slider1 {
  height: 50px;
  width: 100%;
}
.example-thumb1 {
  font-size: 0.9em;
  text-align: center;
  background-color: black;
  color: white;
  cursor: pointer;
  border: 5px solid gray;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.example-thumb1:focus {
  outline: none;
}
.example-track1.example-track1-1 {
  background: #d0d0d0;
}
.example-track1 {
  position: relative;
  background: var(--primary);
}
.example-thumb1.active {
  background-color: grey;
}
.example-mark1 {
  background-color: #fff;
  cursor: pointer;
  border-radius: 50%;
  width: 17px;
  height: 17px;
  border-radius: 9px;
  -webkit-filter: drop-shadow(0px 2px 2.5px rgba(0, 0, 0, 0.17));
  filter: drop-shadow(0px 2px 2.5px rgba(0, 0, 0, 0.17));
  background-color: #97a2b8;
  border: 3px solid #ffffff;
  background: transparent;
}
.horizontal-slider1 .example-track1 {
  top: 20px;
  height: 18px;
  border-radius: 4px;
}
.horizontal-slider1 .example-thumb1 {
  top: 15.5px;
  width: 14px;
  color: transparent;
  border-radius: 2px;
  -webkit-filter: drop-shadow(0px 4px 3.5px rgba(0, 0, 0, 0.06));
  filter: drop-shadow(0px 4px 3.5px rgba(0, 0, 0, 0.06));
  background-color: #073265;
  z-index: 0 !important;
  border: 2px solid transparent;
  padding: 0 3px;
}
.horizontal-slider1 .example-mark1 {
  margin: 0 calc(25px - 6px);
  bottom: calc(50% - 9px);
}
.instant-est-height {
  height: calc(100vh - 7.5rem);
  @screen md {
    height: calc(100vh - 9.5rem);
  }
}
@media screen and (min-width: 768px) {
  .instant-est-height {
    height: calc(100vh - 9.5rem);
  }
}
.z-max {
  z-index: 1000;
}
.z-1001 {
  z-index: 1001;
}
.home-banner-more-desc {
  /* opacity: 0; */
  -webkit-transition: 0.5s ease;
  -o-transition: 0.5s ease;
  transition: 0.5s ease;
}
.home-banner-more-desc.active {
  opacity: 1;
  -webkit-transition: 0.5s ease;
  -o-transition: 0.5s ease;
  transition: 0.5s ease;
}
.what-next-line {
  position: relative;
}
.what-next-line:after {
  content: "";
  position: absolute;
  width: 60px;
  height: 2px;
  /* background: rgba(255, 147, 43, var(--tw-text-opacity));	 */
  background: var(--primary);
  left: 0;
  right: 0;
  bottom: -10px;
  margin: 0 auto;
}
.custom-container-960 {
  max-width: 960px;
  width: 100%;
}
.custom-container-960 {
  max-width: 960px;
  width: 100%;
}
.bg-parallax-solar-page {
  background: url("../public/images/bg/calculations-right-bg.jpeg");
  background-size: 100% auto;
  background-position: 50%;
  background-attachment: fixed;
}
section.bg-parallax-solar-page:before {
  background: rgba(0, 0, 0, 0.6);
}
.bg-parallax-battery {
  background: url("../public/images/bg/battery-claim-your-free-bg.jpeg");
  background-size: 100% 100%;
  background-position: center center;
  background-attachment: fixed;
}
.accordion .accordion__heading div[aria-expanded="true"] h6 {
  color: #073265;
}
.steps-circle-area {
  width: 70px;
  height: 70px;
}
.steps-circle-thumb img {
  margin-top: -3px;
}
.step-border-line {
  position: relative;
}
.step-border-line:after {
  content: "";
  width: 0px;
  height: 48px;
  border-left: 0.1px dashed #d1d1d1;
  position: absolute;
  bottom: -5px;
  left: 35px;
  z-index: 1;
}
.step-border-line.step-border-step1:after {
  height: 17px;
}
.step-border-line.step-border-step2:after {
  height: 16px;
}
.font-configSemiBold {
  font-weight: normal;
}
/* browser compatibility issues */
input[type="number"] {
  -moz-appearance: textfield;
}
::-moz-placeholder {
  opacity: 1;
}
.react-tel-input .country-list {
  overflow: auto !important;
}
/*----------------------- modal scrollbar -------------------------*/
.scroll-bar-modal::-webkit-scrollbar {
  display: block;
}
.scroll-bar-modal::-webkit-scrollbar {
  width: 6px;
  background-color: #fff;
}
.scroll-bar-modal::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #fafafa;
  border-radius: 4px;
}

.scroll-bar-modal::-webkit-scrollbar-thumb {
  background-color: #cac9c9;
  border-radius: 4px;
}

.mask {
  position: absolute;
  left: 0;
  right: 0;
  top: 3rem;
  /* background-color: rgba(255,255,255,0.5); */
  text-align: center;
}
.pei {
  display: inline-block;
  position: relative;
}
.mask > .pei-spinner::before {
  font-size: 4rem;
  margin-top: 240px;
}
.pei-spinner::before {
  content: "";
  vertical-align: text-bottom;
  display: inline-block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 1em;
  height: 1em;
  border: 0.1em solid #ff922baf;
  border-right-color: transparent;
  border-radius: 0.625em;
  -webkit-animation: pei-infinite-spinning 0.75s linear infinite;
  animation: pei-infinite-spinning 0.75s linear infinite;
}

@-webkit-keyframes pei-infinite-spinning {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes pei-infinite-spinning {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.blur {
  filter: blur(4px);
  -webkit-filter: blur(4px);
}
/* .swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal{
  top: 32%;
  left: 0px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  background: #f1f1f1;
  padding: 16px 4px;
  border-radius: 66px;
  width: fit-content !important;
  height: fit-content !important;
}
.swiper-pagination-bullet-active{
  background: var(--primary) !important;
} */

/* loader 2 */
.loader {
  display: block;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #96979b;
  border-radius: 50%;
  width: 1.5rem;
  aspect-ratio: 1/1;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.input-autocomplete-custom input#project-address-input {
  width: 100% !important;
}
.input-autocomplete-custom .css-1g6gooi,
.input-autocomplete-custom .css-1g6gooi > div {
  width: 100% !important;
  z-index: 1;
}
.list {
  @apply gap-4 mt-3 mb-6;
}
.list--loanListStart {
  @apply grid xxs:grid-cols-2 2md:grid-cols-3 xxl:grid-cols-4;
}
.list--loanListCenter {
  @apply flex flex-wrap justify-center items-center;
}
.list__items {
  @apply flex flex-col shadow p-4 rounded bg-white hover:shadow-xl transition duration-500 ease-in-out transform relative;
}
.list--loanListCenter .list__items {
  @apply w-full sm:w-1/2 md:w-2/6 xl:w-1/4;
}

.container-iframe {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
  margin-bottom: 2.5em;
  background: black;
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.bg-blue-150 {
  background: #e2f2fd;
}
.bg-orange-150 {
  background: #fff8ee;
}

.new-jersey-faq .accordion__button[aria-expanded="true"] h1 {
  color: #5baade;
}

.service_new-jersey .project-title-hover {
  position: absolute;
  bottom: 3.4rem;
}
.suntuity h1 span {
  color: #5baade;
}
.getQuoteform_new-jersey {
  @apply bg-white;
}
.suntuity-banner-wrap {
  padding-bottom: 3rem;
}

.suntuity-banner-wrap .banner-desc-one {
  @apply font-primaryMedium;
}

.estimate-from-wrap h2 {
  @apply font-primaryBold;
}
.estimate-from-wrap .button {
  @apply text-xl;
}
.estimate-from-wrap form button {
  @apply bg-suntuityPrimary;
}
/* .estimate-from-wrap form button:hover{
  @apply bg-suntuitySecondary;
} */
.service_new-jersey {
  background: #f1f5f9;
}
/* .estimate-from-wrap form .relative div.border-2,
.estimate-from-wrap form .relative input{
border:none;
@apply border-t-0 border-r-0 border-l-0 border-b border-gray-400 border-solid rounded-none;
} */

.suntuity-banner-wrap .banner-desc-two {
  font-size: 1.1rem;
  line-height: 1.4;
}
.header-new-jersey.header-solid {
  background: rgba(19, 34, 55, 0.92);
}

.new-jersey-faq {
  background: #f5fbff;
}

.state-incetives-border {
  position: relative;
  padding-left: 4rem;
  padding-right: 4rem;
}

.state-incetives-border:before {
  content: "";
  height: 4px;
  border-radius: 10px;
  top: 50%;
  @apply absolute w-12 left-0 bg-suntuitySubSecondary;
}

.state-incetives-border:after {
  content: "";
  height: 4px;
  border-radius: 10px;
  top: 50%;
  @apply absolute w-12 right-0 bg-suntuitySubSecondary;
}

.footer-suntuity {
  background: #2e384d;
}

.quote-form-layoutone_new-jersey .shadow-lg-g,
.quote-form-layoutone_new-jersey .shadow {
  -webkit-box-shadow: none;
  box-shadow: none;
  background: transparent;
}

/* .quote-form_new-jersey.quote-form-layoutone_new-jersey .max-w-48 {
  max-width: 560px;
} */
.quote-form_new-jersey.quote-form-layoutone_new-jersey .max-w-48 {
  max-width: 52rem;
}

@media screen and (min-width: 768px) {
  .suntuity:not(.banner-custom-form) h1 {
    font-size: 3.1rem;
    margin-right: 0;
    margin-bottom: 0.5rem;
  }
}
@media screen and (min-width: 980px) {
  .main-header {
    position: sticky;
    top: 0;
    z-index: 99;
  }
  .quote-form_new-jersey .max-w-48 {
    max-width: 560px;
  }
  .quote-form_new-jersey.quote-form-layoutone_new-jersey .max-w-48 {
    max-width: 560px;
  }
  .quote-form_new-jersey.quote-form-layoutone_new-jersey .max-w-48 {
    max-width: 52rem;
  }
  .quote-form-layoutone_new-jersey form .grid {
    -ms-grid-columns: minmax(0, 1fr) 1rem minmax(0, 1fr) 1rem minmax(0, 1fr);
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 1rem;
  }
}
@media screen and (min-width: 1280px) {
  .quote-form_new-jersey {
    margin-top: -1rem;
  }
  .AmountModal__Overlay.recomdation-battery .ReactModal__Content--after-open {
    min-width: 864px;
  }
  .AmountModal__Overlay.recomdation-battery.modal-pdf
    .ReactModal__Content--after-open {
    min-width: 1020px;
  }
}

@media screen and (max-width: 639px) {
  .list--loanListCenter .list__items {
    width: 100%;
  }
}
.list-hint {
  @apply text-sm font-primaryMedium my-1.5 leading-5 text-bodyColor w-full;
}
.list-message {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  @apply px-4 xxs:px-8 md:px-16 py-2 bg-primary bg-opacity-10 text-center leading-4 text-titleColor font-primaryMedium rounded-full mt-4 mx-auto;
}

.loan_list_mobile {
  min-height: 428px;
  height: 100%;
  @apply flex flex-col shadow p-4 m-2  rounded bg-white hover:shadow-xl transition duration-500 ease-in-out transform relative;
}
@media screen and (max-width: 370px) {
  .loan_list_mobile {
    min-height: 470px;
  }
}

.loader-overlay {
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  background: #ffffff4f;
}
.bing-map > div {
  z-index: 1;
}
@media screen and (max-width: 979px) {
  .project-hover {
    padding: 1.5rem 1.25rem 1.85rem;
  }
  .project-item:hover .project-hover .project-title {
    -webkit-transform: translateY(0.5em);
    -ms-transform: translateY(0.5em);
    transform: translateY(0.5em);
    line-height: 1.1;
    font-size: 1.5rem;
    margin-bottom: 15px;
  }
  .project-description {
    margin-top: 6%;
    font-size: 1rem;
  }
  .project-hover-mob {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .project-item .project-hover.project-hover-mob .project-description,
  .project-item .project-hover.project-hover-desktop .project-description {
    font-size: 16px;
  }

  .project-item.project-item-mob .project-hover {
    background-color: rgba(255, 255, 255, 0.8);
  }
  .project-item.project-item-mob .project-hover .project-description {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
    -webkit-transition-duration: 0.5s;
    -o-transition-duration: 0.5s;
    transition-duration: 0.5s;
    -webkit-transition-delay: 0.1s;
    -o-transition-delay: 0.1s;
    transition-delay: 0.1s;
    -webkit-transition: all 0.5s 0.1s;
    -o-transition: all 0.5s 0.1s;
    transition: all 0.5s 0.1s;
  }
  .project-item.project-item-mob .project-title.project-title-main {
    display: none;
  }
  .trusted-partner-carousel .carousel-one-logo {
    min-height: 78px;
  }
  .bg-parallax-solar-page,
  .bg-parallax-battery {
    background-size: cover;
  }
  .bnr-layout-3-min-height{
    min-height: 420px;
  }
}
@media screen and (max-width: 768px) {
  .confirm-box {
    width: 100%;
  }
  .self-site-survey .ReactModal__Content.ReactModal__Content--after-open {
    width: 100%;
  }
  .video-container-iframe iframe {
    height: 200px !important;
  }
  .video-container-what-next iframe {
    height: 200px !important;
  }
  .leadForm .ReactModal__Content.ReactModal__Content--after-open {
    width: 100%;
    border-radius: 0 !important;
  }
  .filter-none {
    -webkit-filter: none;
    filter: none;
  }
  .filter-gray {
    -webkit-filter: grayscale(1);
    filter: grayscale(1);
  }
  .bnr-layout-3-min-height{
    min-height: 340px;
  }
}

@media screen and (max-width: 767px) {
  .project-hover {
    padding: 1rem 1.25rem 1.85rem;
  }
  .project-hover.project-hover-mob .project-title {
    opacity: 1;
    -webkit-transform: translateY(10px);
    -ms-transform: translateY(10px);
    transform: translateY(10px);
    -webkit-transform: translateY(0.5em);
    -ms-transform: translateY(0.5em);
    transform: translateY(0.5em);
    line-height: 1.1;
    font-size: 1.3rem;
    margin-bottom: 15px;
  }
  .consultation {
    background-position: right;
    height: 360px;
  }
  .designs {
    background-position: top;
    height: 360px;
  }
  .permit {
    background-position: center right;
    height: 360px;
  }
  .install {
    background-position: center;
    height: 360px;
  }
  .activation {
    background-position: center;
    height: 360px;
  }
}
@media screen and (max-width: 640px) {
  .account-bg {
    height: auto;
  }
  .product-service-wrap .img-wrap img {
    max-height: 210px;
  }
  .battery-carousel-wrap .img-wrap img {
    max-height: 192px;
  }
  .company-carousel-wrap .img-wrap img {
    max-height: 222px;
  }
  .project-hover {
    padding: 0.5rem 1.25rem 1.85rem;
  }
  .project-item:hover .project-hover .project-title {
    -webkit-transform: translateY(0.5em);
    -ms-transform: translateY(0.5em);
    transform: translateY(0.5em);
    line-height: 1.1;
    font-size: 1.3rem;
    margin-bottom: 15px;
  }
  .project-description {
    margin-top: 7%;
    font-size: 1rem;
  }
  /* .project-hover-desktop{display: none;} */
  .project-hover-mob {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .step-border-line {
    position: relative;
  }
  .step-icon-wrap {
    z-index: 2;
    padding: 8px 4px;
    overflow: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .step-border-line:after {
    content: "";
    width: 0px;
    height: 69%;
    border-left: 0.1px dashed #d1d1d1;
    position: absolute;
    bottom: 0;
    left: 23px;
    z-index: 1;
  }
  .step-border-line.step-border-step1:after {
    height: 59%;
  }
  .step-border-line.step-border-step2:after {
    height: 61%;
  }
}
@media screen and (max-width: 600px) {
  .project-item .project-hover .project-title {
    font-size: 1.65rem;
    line-height: 1.2;
  }
  .project-hover {
    padding: 0.45rem 1.5rem 1.25rem;
  }
  .project-item:hover .project-hover .project-description {
    font-size: 16px;
  }
  .project-hover.project-hover-mob .project-title {
    opacity: 1;
    -webkit-transform: translateY(10px);
    -ms-transform: translateY(10px);
    transform: translateY(10px);
    -webkit-transform: translateY(0.5em);
    -ms-transform: translateY(0.5em);
    transform: translateY(0.5em);
    line-height: 1.1;
    font-size: 1.3rem;
    margin-bottom: 15px;
  }
}
@media screen and (max-width: 480px) {
  .font-15 {
    font-size: 14px;
  }
  .battery-banner {
    background-position: center left;
  }
  .account-banner {
    height: 200px;
  }
  .contract-banner {
    height: 200px;
  }
  .why-solar-banner {
    height: 520px;
  }
  .service-newjersey .project-hover {
    padding: 0.45rem 1.25rem 1.25rem 1.5rem;
  }

  .state-incetives-border {
    position: relative;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .state-incetives-border:before {
    left: -2rem;
    width: 2rem;
    top: 16px;
  }

  .state-incetives-border:after {
    right: -2rem;
    width: 2rem;
    top: 16px;
  }
  .service_new-jersey .project-item img {
    min-height: 360px;
  }
  .service_new-jersey .project-title-hover {
    bottom: 2.5rem;
  }
}
@media screen and (max-width: 439px) {
  .sm-device-hidden {
    @apply hidden;
  }
}
@media screen and (max-width: 380px) {
  .home form h2.text-2xl {
    font-size: 1.35rem;
  }
}
@media screen and (max-width: 360px) {
  .mobile-se-text {
    @apply text-base;
  }
  .home form h2.text-2xl {
    font-size: 1.25rem;
  }
  .mobile-se-text {
    @apply text-base;
  }
}
.accordion_sidebar {
  padding: 4px 8px 0 !important;
}
.lead-form-dialog {
  position: relative;
  overflow: auto;
  height: 80vh;
}

.ReactModal__Content.ReactModal__Content--after-open.tool-modal-size {
  background: white;
  height: 72%;
  width: 44%;
  position: absolute;
  margin: auto !important;
  inset: 50% auto auto 50% !important;
  -webkit-transform: translate(-50%, -50%) !important;
  -ms-transform: translate(-50%, -50%) !important;
  transform: translate(-50%, -50%) !important;
  border-radius: 8px !important;
  overflow: auto;
  outline: none !important;
}
@media screen and (max-width: 1280px) {
  .ReactModal__Content.ReactModal__Content--after-open.tool-modal-size {
    width: 64%;
  }
}
@media screen and (max-width: 768px) {
  .ReactModal__Content.ReactModal__Content--after-open.tool-modal-size {
    width: 80%;
  }
}
.select-field-py {
  padding-top: 10px;
  padding-bottom: 10px;
}
.leadInitialForm .ReactModal__Content.ReactModal__Content--after-open {
  width: 36%;
  min-width: auto;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  overflow-y: auto !important;
  border-radius: 6px !important;
  bottom: 3rem !important;
  top: unset !important;
  -webkit-transform: translate(-50%, 0) !important;
  -ms-transform: translate(-50%, 0) !important;
  transform: translate(-50%, 0) !important;
}
.leadInitialForm {
}
@media screen and (max-width: 980px) {
  .leadInitialForm .ReactModal__Content.ReactModal__Content--after-open {
    width: 54%;
  }
  .loan-list .react-multi-carousel-list {
    padding-bottom: 2rem;
  }
}
@media screen and (max-width: 520px) {
  .leadInitialForm .ReactModal__Content.ReactModal__Content--after-open {
    width: 90%;
  }
}
.chart-dynamic svg g path {
  fill: var(--primary);
}
.drift-widget-controller-icon.drift-widget-controller-icon.drift-widget-controller-icon {
  background: var(--primary) !important;
}
.bg-solid {
  background-color: #2d4b5e;
}

/* New jersey transparent form */

.quote-form_transparent_new-jersey .estimate-from-wrap > div > .bg-white {
  background: transparent;
}
.quote-form_transparent_new-jersey form h2 {
  display: none;
}
.quote-form_transparent_new-jersey .relative.pb-6 {
  padding-bottom: 1.25rem;
}
/* .quote-form_transparent_new-jersey form{
  padding-top: 0 !important;
} */
.quote-form_transparent_new-jersey .estimate-from-wrap > div > div {
  max-width: 100% !important;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.quote-form_transparent_new-jersey .estimate-from-wrap form {
  margin: auto;
}
.quote-form_transparent_new-jersey .estimate-from-wrap form + div.md\:pb-8 {
  margin: auto;
  text-align: center;
}
.quote-form_transparent_new-jersey .estimate-from-wrap form + div > p {
  color: white;
}
.quote-form_transparent_new-jersey .estimate-from-wrap form + div > p > a {
  color: #a4a4b9;
}
.quote-form_transparent_new-jersey .estimate-from-wrap form + div > p > span {
  font-style: unset;
}
/* @media screen and (min-width: 1024px) {	
  .quote-form_transparent_new-jersey .home.landing-solar-panels-bay-area-ca{	
    max-height: 800px !important;	
  }	
} */
/* .quote-form_transparent_new-jersey .css-yk16xz-control > div{
  padding-top: 0.12rem !important;
  padding-bottom: 0.12rem !important;
} */

/* .quote-form_transparent_new-jersey .css-1pahdxg-control .css-g1d714-ValueContainer, 
.quote-form_transparent_new-jersey.css-yk16xz-control .css-g1d714-ValueContainer {
  max-height: 38px;
} */
/* .quote-form_transparent_new-jersey input.text-sm.md\:text-base.focus\:outline-none.focus\:ring-2.focus\:ring-titleColor.pl-10.sm\:pl-12.pr-3.p-3.border-2.border-gray-200.rounded.w-full.placeholder-current.text-bodyColor{
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
} */

.quote-form_transparent_new-jersey .relative.pb-6 input {
  padding-top: 0.45rem;
  padding-bottom: 0.45rem;
}
.quote-form_transparent_new-jersey .css-yk16xz-control > .css-1hwfws3 {
  padding-top: 0.12rem !important;
  padding-bottom: 0.12rem !important;
}
.quote-form_transparent_new-jersey .css-yk16xz-control > div:first-child,
.quote-form_transparent_new-jersey .css-1pahdxg-control > div:first-child {
  padding-top: 0.2rem !important;
  padding-bottom: 0.2rem !important;
}
.quote-form_transparent_new-jersey .css-yk16xz-control > div.css-1hwfws3,
.quote-form_transparent_new-jersey
  .css-1pahdxg-control
  > div.css-g1d714-ValueContainer {
  padding-top: 0.2rem !important;
  padding-bottom: 0.2rem !important;
}
.quote-form_transparent_new-jersey
  .estimate-from-wrap
  form
  .input-autocomplete-custom
  svg {
  top: 6px;
}
.quote-form_transparent_new-jersey .relative.pb-6 svg {
  top: 9px;
}
.quote-form_transparent_new-jersey .select-box-wrap .css-1wa3eu0-placeholder {
  top: 51%;
}
.estimate-from-wrap form button {
  padding-top: 0.85rem;
  padding-bottom: 0.85rem;
}

@media screen and (min-width: 768px) {
  .quote-form_transparent_new-jersey .estimate-from-wrap form {
    max-width: 32rem;
  }
  .quote-form_transparent_new-jersey .estimate-from-wrap form.md\:pt-8 {
    padding-top: 0.25rem;
  }
  .custom-container {
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (min-width: 980px) {
  .quote-form_transparent_new-jersey .estimate-from-wrap form + div.md\:pb-8 {
    max-width: 51rem;
  }
}
@media screen and (min-width: 1024px) {
  section.banner-section-layouttwo .container .xl\:pt-12 {
    @apply pt-8;
  }
  .loan-palModal-section {
    min-width: 768px;
    min-height: 380px;
  }
}
@media screen and (min-width: 1201px) {
  .banner-section-layouttwo h1 {
    font-size: 2.5rem;
  }
}
@media screen and (min-width: 1351px) {
  .banner-section-layouttwo h1 {
    font-size: 2.75rem;
  }
}

@media screen and (max-width: 600px) {
  .quote-form_transparent_new-jersey .estimate-from-wrap form {
    @apply px-2;
  }
  .quote-form_transparent_new-jersey .estimate-from-wrap form svg {
    top: 5px;
  }
  .suntuity-banner-wrap.layoutone {
    padding-bottom: 1rem;
  }
}
.referral-partner-form .radio-wrap input[type="radio"] {
  width: 18px;
  height: 18px;
  position: relative;
  top: 3px;
  cursor: pointer;
}
.referral-partner-form .radio-wrap label {
  margin-right: 1em;
  padding-left: 2px;
  cursor: pointer;
}
.referral-form .css-yk16xz-control > div:first-child {
  padding: 0.4rem !important;
}
.simplestep-arrow-icon {
  right: -2rem;
  top: 5rem;
}
.disclaimer-section {
  background-color: #ffeec2;
  -webkit-box-shadow: 0px 4px 12px #f1c74c;
  box-shadow: 0px 4px 12px #f1c74c;
  border-radius: 12px;
}
.EstimateDetailModalPortal
  .form-control-overlay.optimizeModal
  .ReactModal__Content--after-open {
  max-height: 89%;
}
.shareBlock {
  /* border: 1px solid #B9CAE1; */
  /* background: #FFFFFF; */
  /* border: 1px solid #B9CAE1; */
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 20px 0;
}
.shareBlock-border {
  border-color: #b9cae1;
}
.btnshare {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  gap: 0.25rem;
  background: #f1f6ff;
  padding: 14px 14px;
  /* border-radius: 0px 4px 4px 0px; */
}
.cardView {
  -webkit-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.07));
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.07));
  background: #ffffff;
  border: 1.46362px solid #ebebeb;
  border-radius: 10px;
  padding: 35px 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 0.5rem;
}
.profiletable table tbody tr:last-child td {
  @apply rounded-md;
}
.profiletable table tbody tr:last-child {
  @apply border-0;
}

.affilate-banner {
  background: url("../public/images/affiliate/affiliate-banner.jpeg");
  background-size: cover;
  background-position: center;
  height: 100%;
  min-height: 520px;
  width: 100%;
}
.bg-affilate-variety {
  background: url("../public/images/affiliate/variety-reward-bg.jpeg");
  background-size: cover;
  background-position: center;
  height: 100%;
}
.bg-referrals-program {
  background: url("../public/images/affiliate/bg-referral-program.jpeg");
  background-size: cover;
  background-position: center;
  height: 100%;
}

.affiliate-work-list {
  counter-reset: orderedlist;
}
.affiliate-work-list li {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 0 2em 1fr;
  grid-template-columns: 0 1fr;
  grid-gap: 2em;
}

.affiliate-work-list li::before {
  counter-increment: orderedlist;
  content: counter(orderedlist);
  font-size: 2.25rem;
  line-height: 1;
  width: 1.5rem;
  padding-top: 0;
  text-align: center;
  color: var(--primary);
  margin-top: -2px;
}

.multistep-form {
}
.multistep-form__steps {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem 2rem;
}
.affiliate-address div {
  color: #61666e !important;
}
@media screen and (max-width: 1260px) {
  .affiliate-work-list li::before {
    font-size: 2rem;
  }
}
.multistep-form__steps > .steps {
  display: inline-block;
  font-size: 1rem;
  width: 2.5em;
  aspect-ratio: 1/1;
  /* margin: 1em; */
  border-radius: 50%;
  text-align: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  --tw-bg-opacity: 0.7;
  background-color: rgba(46, 56, 77, var(--tw-bg-opacity));
  color: #fff;
}
.multistep-form__steps > .steps.active {
  background-color: var(--primary);
}
.multistep-form__stepsDetails > .step-content:not(.active) {
  display: none !important;
}
.multistep-form .step-action {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.multistep-form .step-action .next-btn {
  margin-left: auto;
}

.lead-form-dialog > form {
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: auto 1rem -webkit-max-content;
  -ms-grid-rows: auto 1rem max-content;
  grid-template-rows: auto -webkit-max-content;
  grid-template-rows: auto max-content;
  /* height: 100%; */
  width: 100%;
  /* padding: 2rem; */
  gap: 1rem;
}
.lead-form-dialog .lead-form-dialog__body {
  position: relative;
}
@media screen and (min-width: 821px) {
  .affilate-banner {
    height: 85vh;
    min-height: 580px;
  }
}
.lead-form-dialog .lead-form-dialog__body > div {
  /* position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto; */
}

@media screen and (max-width: 639px) {
  .affilate-banner {
    background: url("../public/images/affiliate/affiliate-mobile-banner.jpeg");
    background-size: 100% 100%;
    background-position: top center;
    height: 100%;
    min-height: 520px;
    background-repeat: no-repeat;
  }
  .btnsendshare.btnshare {
    padding: 8px 14px;
  }
  .tabs .tab_wrap .tabs__tab svg {
    margin-right: 0;
  }
  .lead-form-dialog > form {
    gap: 0.5rem;
  }
}

.estimate {
}
.estimate-design {
  height: 100vh;
  overflow: hidden;
}
.spl__estimate-design.estimate-design {
  height: 100vh;
  overflow: auto;
  margin-bottom: 20px;
}
.overflowHidden.spl__estimate-design.estimate-design {
  height: Calc(100vh - 58px);
  padding-bottom: 0px;
  margin-bottom: 0px;
  overflow: hidden;
}

.estimate-design .chooseOffsetTab {
  display: -ms-grid;
  display: grid;
  /* grid-template-rows: max-content auto; */
}

.step-progress.desktop {
  position: fixed;
  top: 1rem;
  left: 50%;
  -webkit-transform: translate(-50%);
  -ms-transform: translate(-50%);
  transform: translate(-50%);
}
.step-progress.desktop .step-progress-bar {
  background: #ccddf2;
  border-radius: 1rem;
  overflow: hidden;
}
.step-progress.desktop .step-progress-bar > span {
  background: #1558a6;
  height: 0.5rem;
  width: 100%;
  margin: 0 !important;
  border-radius: 0;
  border: 0;
}
.step-progress.desktop .step-progress-bar.secondaryAsRgb {
  background: rgba(var(--secondaryAsRgb), 0.5);
}
.step-progress.desktop .step-progress-bar.secondaryAsRgb > span {
  background: var(--secondary);
}
.step-progress.desktop .step-progress-bar > .active ~ span {
  background: transparent;
}
.batteryRecomdationsTab__innerTab {
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: -webkit-max-content 1rem auto;
  -ms-grid-rows: max-content 1rem auto;
  grid-template-rows: -webkit-max-content auto;
  grid-template-rows: max-content auto;
  height: 100%;
  gap: 1rem;
}
.instant-estimate-inner {
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: -webkit-max-content 1rem auto;
  -ms-grid-rows: max-content 1rem auto;
  grid-template-rows: -webkit-max-content auto;
  grid-template-rows: max-content auto;
  height: 100%;
  gap: 1rem;
}
.hide-drift #drift-frame-controller,
.hide-drift #chat-widget-container,
.hide-drift #drift-frame-chat,
.hide-drift chat-widget {
  display: none !important;
}
.cursor-outside-modal {
  position: sticky;
  top: 0;
  right: 1rem;
  margin-right: 1rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: auto;
  cursor: pointer;
  /* transform: translate(); */
}
button:disabled {
  cursor: not-allowed;
}
.footer-suntuity-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 49%;
}
.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
/*-------------------------------- new-home css----------------------------- */
.section-heading {
  @apply text-2xl leading-7 sm:text-3xl md:text-3xl xl:text-4xl xd:text-4xxl mb-2 xxs:mb-2.5 sm:mb-3 xl:mb-3.5 xd:mb-4 text-titleColor font-sfBold;
}
.section-paragraph {
  @apply text-base sm:text-lg sm:leading-7 xl:text-xl font-sfMedium;
  color: #565c63;
}
.banner-orange-btn {
  @apply transition duration-500 ease-in-out transform hover:-translate-y-1 hover:opacity-90 text-black border-2 border-primary bg-primary inline-block rounded font-sfSemiBold px-8 py-2.5 sm:px-10 sm:py-3 text-lg xl:text-xl w-full md:w-auto text-center;
}

.testimonials-bg {
  background: url("../public/images/home/testimonials-bg.png");
  background-size: cover;
  background-position: center;
}
.section-efficient-home-bg {
  background: url("../public/images/home/section-efficient-home-bg.jpg");
  background-size: cover;
  background-position: center;
}

.partner-carousel .react-multi-carousel-item .grid {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  min-height: 72px;
}
.home-increae-energy-carousel
  .carousel-container-of-increase-Energy-section
  .custom-dot-list
  .react-multi-carousel-dot
  button {
  border-color: transparent;
  background-color: #fff;
  width: 23px;
  height: 6px;
  border-radius: 1px;
}
.home-increae-energy-carousel
  .carousel-container-of-increase-Energy-section
  .custom-dot-list
  .react-multi-carousel-dot.react-multi-carousel-dot--active
  button {
  border-color: transparent;
  background-color: var(--primary);
}

.page-min-height {
  min-height: calc(100vh - 4rem);
}
.states-territories-bg {
  background-color: #edf3ff;
}
.states-territories-section .state-shadow {
  -webkit-box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.09);
  box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.09);
  border-radius: 0px 0px 10px 10px;
}
.states-territories-section .state-shadow:hover {
  -webkit-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.states-territories-section .state-img {
  -webkit-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.states-territories-section .state-list:hover .state-img {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.states-territories-section .state-img-wrapper {
  position: relative;
}
.states-territories-section .state-list {
  cursor: pointer;
}
.states-territories-section .state-list .state-img-wrapper:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0.3;
  -webkit-transition: 0.5s ease;
  -o-transition: 0.5s ease;
  transition: 0.5s ease;
  background-color: #000;
  /* -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1); */
}
.states-territories-section .state-list:hover .state-img-wrapper:after {
  opacity: 0;
  /* -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    -webkit-transition: .3s ease;
    transition: .3s ease; */
}

.right-opacity {
  background: rgba(13, 15, 23, 0.55);
  opacity: 0.94;
}
.inner-right-opacity {
  background: rgba(0, 0, 0, 0.35);
}

.simplestep-arrow-icon {
  right: -2rem;
  top: 5rem;
}

.inner-right-opacity::after {
  content: "";
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  /* background: rgba(0, 0, 0, 0.1); */
  filter: blur(3px);
  -webkit-filter: blur(3px);
  /* backdrop-filter: blur(3px); */
}
/* .section-efficient-home.right-section::after{
  content: "";
  position: absolute;
  left: -1px;
  z-index: 999;
  top: 50%;
  transform: translate(-50%, -50%);
  background: url("../public/images/home/state-img/arow-icon.svg");
  width: 24px;
  height: 24px;
  object-fit: cover;
  background-repeat: no-repeat;
  background-repeat: no-repeat;
  object-position: center;
  cursor: pointer
} */
.SectionGetEstimateNow-bg {
  background: #f4f6ff;
}
/* Section Trusted Partners shadow */
.stp-shadow {
  -webkit-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.custom-accordion .accordion__button:before {
  /* display: inline-block;
  content: none; */
  height: 22px;
  width: 22px;
  background: url("../public/images/faq-icon-open.png");
  background-size: cover !important;
  border: none !important;
  -webkit-transform: none !important;
  -ms-transform: none !important;
  transform: none !important;
  margin-right: 0px !important;
  margin-top: 0px !important;
}
@media (min-width: 640px) {
  .custom-accordion .accordion__button:before {
    /* display: inline-block;
    content: none; */
    height: 25px;
    width: 25px;
    background: url("../public/images/faq-icon-open.png");
    background-size: cover !important;
    border: none !important;
    -webkit-transform: none !important;
    -ms-transform: none !important;
    transform: none !important;
    margin-right: 0px !important;
    margin-top: 0px !important;
  }
  .custom-accordion .accordion__panel {
    padding: 14px 0px 16px 0px !important;
  }
}
.custom-accordion .accordion__button[aria-expanded="true"]::before,
.custom-accordion .accordion__button[aria-selected="true"]::before {
  background: url("../public/images/faq-icon-close.png");
}
.custom-accordion .accordion__button[aria-expanded="true"] h3 {
  @apply text-primary;
}
.custom-accordion .accordion__panel {
  padding: 10px 0px 16px 0px !important;
}
.carousel-container-of-increase-Energy-section
  .custom-dot-list
  .react-multi-carousel-dot
  button {
  border-color: transparent;
  background-color: #fff;
}
.carousel-container-of-increase-Energy-section
  .custom-dot-list
  .react-multi-carousel-dot.react-multi-carousel-dot--active
  button {
  border-color: transparent;
  background-color: var(--primary);
}
.carousel-container-of-increase-Energy-section .custom-dot-list {
  padding-bottom: 10px;
}
.carousel-container-of-increase-Energy-section .carousal-item {
  -webkit-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  cursor: pointer;
}
.carousel-container-of-increase-Energy-section .carousal-item:hover {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}
.carousel-container-of-about-section-page {
  padding-bottom: 40px;
}
.carousel-container-of-about-section-page
  .custom-dot-list
  .react-multi-carousel-dot
  button {
  border-color: transparent;
  background-color: #fff;
}
.carousel-container-of-about-section-page
  .custom-dot-list
  .react-multi-carousel-dot.react-multi-carousel-dot--active
  button {
  border-color: transparent;
  background-color: var(--primary);
}
.carousel-container-of-about-section-page .custom-dot-list {
  padding-bottom: 10px;
}
.section-best-price-bg {
  background: url("../public/images/home/section-efficient-home-bg.jpg");
  background-size: cover;
  background-position: center;
  -o-object-fit: cover;
  object-fit: cover;
  background-repeat: no-repeat;
  -o-object-position: center;
  object-position: center;
}
/* .why-solar-banner-new {
  background: url("../public/images/home/why-solar/why-solarbanner.png");
  background-size: cover;
  background-position: center;
  height: 600px;
} */
.section-best-price-bg {
  background: url("../public/images/home/why-solar/best-price-bg.jpg");
  background-size: cover;
  background-position: center;
}
.bg-of-increaseEnergy-and-simpleeSteps {
  background-color: #f9faff;
}
.simple-step-card .max-w-260 {
  max-width: 290px;
}
.start-saving-now-bg {
  background: #f0f8ff;
}

.product.section-solar-banner {
  background: url("../public/images/home/product-solar/solar-banner.jpeg");
  background-size: cover;
  background-position: center;
  -o-object-fit: cover;
  object-fit: cover;
  background-repeat: no-repeat;
  -o-object-position: center;
  object-position: center;
  max-height: 700px;
}
.why-solar-banner-new {
  background: url("../public/images/home/why-solar/why-solarbanner.png");
  background-size: cover;
  background-position: center;
  -o-object-fit: cover;
  object-fit: cover;
  background-repeat: no-repeat;
  -o-object-position: center;
  object-position: center;
  max-height: 700px;
}
.product.section-battery-banner {
  background: url("../public/images/home/product-battery/product-battery-banner.jpg");
  background-size: cover;
  background-position: center;
  -o-object-fit: cover;
  object-fit: cover;
  background-repeat: no-repeat;
  -o-object-position: center;
  object-position: center;
  max-height: 700px;
}
.productSolar.section-dont-see-bg {
  background: url("../public/images/home/product-solar/dont-see-want.jpeg");
  background-size: cover;
  background-position: center;
  -o-object-fit: cover;
  object-fit: cover;
  background-repeat: no-repeat;
  -o-object-position: center;
  object-position: center;
}
.productBattery.section-BatteryRecommendation-bg {
  background: url("../public/images/home/product-battery/section-BatteryRecommendation-img.jpg");
  background-size: cover;
  background-position: center;
  -o-object-fit: cover;
  object-fit: cover;
  background-repeat: no-repeat;
  -o-object-position: center;
  object-position: center;
}
.section-solar-pepresentative-bg {
  background: url("../public/images/home/why-solar/solar-pepresentative-bg.jpg");
  background-size: cover;
  background-position: center;
  -o-object-fit: cover;
  object-fit: cover;
  background-repeat: no-repeat;
  -o-object-position: center;
  object-position: center;
}
.about.section-about-banner {
  background: url("../public/images/home/about-us/about-banner.jpg");
  background-size: cover;
  background-position: center;
  -o-object-fit: cover;
  object-fit: cover;
  background-repeat: no-repeat;
  -o-object-position: center;
  object-position: center;
  max-height: 700px;
}
.section-career-banner {
  background: url("../public/images/home/career-bg.jpeg");
  background-size: cover;
  background-position: center;
  -o-object-fit: cover;
  object-fit: cover;
  background-repeat: no-repeat;
  -o-object-position: center;
  object-position: center;
  max-height: 700px;
}
.dark-blue-bg {
  background: #073265;
}
.customer-prefer-icon-wrapper {
  cursor: pointer;
  width: 166px;
  margin: 0 auto;
  height: 156px;
}
.customer-prefer-icon-wrapper .customer-prefer-icon {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}
.customer-prefer-icon-wrapper:hover .customer-prefer-icon {
  -webkit-transform: scale(0.9);
  -ms-transform: scale(0.9);
  transform: scale(0.9);
}
.simple-step-card .card-image-wrapper {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  cursor: pointer;
}
.simple-step-card .card-image-wrapper:hover {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}
.marker-primary li::marker {
  color: var(--primary);
}
.shopnow-landing .min-h-screen {
  min-height: 95%;
}

@media screen and (max-width: 767px) {
  .simplestep-arrow-icon {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}

@media screen and (min-width: 1501px) {
  .footer-suntuity-container {
    padding-top: 45%;
  }
}
@media screen and (min-width: 1541px) {
  .footer-suntuity-container {
    padding-top: 44.15%;
  }
}
@media screen and (min-width: 1601px) {
  .footer-suntuity-container {
    padding-top: 42.5%;
  }
}
@media screen and (min-width: 1700px) {
  .footer-suntuity-container {
    padding-top: 39.85%;
  }
}
@media screen and (min-width: 1800px) {
  .footer-suntuity-container {
    padding-top: 37.45%;
  }
}
@media screen and (min-width: 1900px) {
  .footer-suntuity-container {
    padding-top: 35.15%;
  }
}
@media screen and (min-width: 1060px) and (max-width: 1427px) {
  .footer-suntuity-container {
    min-height: 680px;
  }
}
@media screen and (min-width: 1060px) and (max-width: 1427px) {
  .footer-suntuity-container {
    min-height: 680px;
  }
}
@media screen and (min-width: 1018px) and (max-width: 1059px) {
  .footer-suntuity-container {
    min-height: 746px;
  }
}
@media screen and (min-width: 850px) and (max-width: 1017px) {
  .footer-suntuity-container {
    min-height: 746px;
  }
}
@media screen and (min-width: 769px) and (max-width: 849px) {
  .footer-suntuity-container {
    min-height: 945px;
  }
}
@media screen and (min-width: 541px) and (max-width: 768px) {
  .footer-suntuity-container {
    min-height: 1014px;
  }
}
@media screen and (max-width: 540px) {
  .footer-suntuity-container {
    min-height: 1710px;
  }
  .shopnow-landing .h-screen {
    height: 100%;
  }
  .shopnow-landing .min-h-screen {
    min-height: 100%;
  }
  .checkbox-container .check:after {
    width: 5px;
    height: 11px;
    left: 6px;
    top: 1px;
  }
}
@media screen and (min-width: 414px) and (max-width: 539px) {
  .footer-suntuity-container {
    min-height: 1678px;
  }
}
@media screen and (min-width: 383px) and (max-width: 396px) {
  .footer-suntuity-container {
    min-height: 1676px;
  }
}
@media screen and (max-width: 382px) {
  .footer-suntuity-container {
    min-height: 1692px;
  }
}
@media screen and (max-width: 376px) {
  .footer-suntuity-container {
    min-height: 1691px;
  }
}

/**Address**/
.css-yk16xz-control > div:last-child,
.css-1pahdxg-control > div:last-child {
  display: none;
}
.css-yk16xz-control > div:first-child,
.css-1pahdxg-control > div:first-child {
  padding: 0.375rem 0.5rem !important;
}

.shopnow-form .css-yk16xz-control > div:first-child,
.shopnow-form .css-1pahdxg-control > div:first-child {
  padding: 0.5rem !important;
}
.shopnow-form .css-1wa3eu0-placeholder {
  font-size: 16px;
}
.shopnow-form input::-webkit-input-placeholder {
  font-size: 16px;
}
.shopnow-form input::-moz-placeholder {
  font-size: 16px;
}
.shopnow-form input:-ms-input-placeholder {
  font-size: 16px;
}
.shopnow-form input::-ms-input-placeholder {
  font-size: 16px;
}
.shopnow-form input::placeholder {
  font-size: 16px;
}
.shopnow-form .css-1g6gooi {
  font-size: 16px;
}
.shopnow-form .css-1uccc91-singleValue {
  font-size: 16px;
}
@media (min-width: 640px) {
  .shopnow-form .css-yk16xz-control > div:first-child,
  .shopnow-form .css-1pahdxg-control > div:first-child {
    padding: 0.9rem 0.6rem !important;
  }
  .shopnow-form .css-1wa3eu0-placeholder {
    font-size: 19px;
  }
  .shopnow-form input::-webkit-input-placeholder {
    font-size: 19px;
  }
  .shopnow-form input::-moz-placeholder {
    font-size: 19px;
  }
  .shopnow-form input:-ms-input-placeholder {
    font-size: 19px;
  }
  .shopnow-form input::-ms-input-placeholder {
    font-size: 19px;
  }
  .shopnow-form input::placeholder {
    font-size: 19px;
  }
  .shopnow-form .css-1g6gooi {
    font-size: 19px;
  }
  .shopnow-form .css-1uccc91-singleValue {
    font-size: 19px;
  }
}

.shopnow-form .css-2b097c-container {
  @apply focus:ring-0 outline-none text-sm xxs:text-base cursor-text;
  ring-shadow: none;
}
.shopnow-form .css-1pahdxg-control {
  @apply ring-0 border-0 cursor-text !important;
}
.shopnow-form .css-1wa3eu0-placeholder {
  @apply text-bodyColor cursor-text !important;
}
.bg-layer-gray:before {
  content: "";
  background: #0000006e;
  z-index: 1;
  @apply inset-x-0 inset-y-0 w-full h-full absolute;
}
.react-multi-carousel-list.carousel-container-panel-list {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.cash-tooltip {
  top: 100%;
  left: 0;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.section-wrapper {
  width: 100%;
}

@media (min-width: 768px) {
  .section-wrapper {
    margin-left: auto;
    margin-right: auto;
    max-width: 80rem;
  }
}

@media (min-width: 1680px) {
  .section-wrapper {
    width: 100%;
  }

  @media (min-width: 480px) {
    .section-wrapper {
      max-width: 480px;
    }
  }

  @media (min-width: 640px) {
    .section-wrapper {
      max-width: 640px;
    }
  }

  @media (min-width: 768px) {
    .section-wrapper {
      max-width: 768px;
    }
  }

  @media (min-width: 980px) {
    .section-wrapper {
      max-width: 980px;
    }
  }

  @media (min-width: 1024px) {
    .section-wrapper {
      max-width: 1024px;
    }
  }

  @media (min-width: 1280px) {
    .section-wrapper {
      max-width: 1280px;
    }
  }

  @media (min-width: 1400px) {
    .section-wrapper {
      max-width: 1400px;
    }
  }

  .section-wrapper {
    margin-left: auto;
    margin-right: auto;
  }
}

.stay-ahead-bg {
  background: url("../public/images/home/ai/stay-ahead-bg.png");
  background-size: cover;
  background-position: center;
}
.text-paragraph-blue {
  color: #353e49;
}

.h-screen-90 {
  height: 90vh;
}
.loan-card button:disabled {
  opacity: 0.5;
}
@media screen and (min-width: 640px) and (max-width: 979px) {
}
@media screen and (max-width: 539px) {
  .mobile-min-height {
    min-height: 330px;
  }
}

.sunnova-card {
  width: 50%;
  padding: 1rem;
  border: 1px solid #000;
}
.sunnova-lable {
  @apply text-base text-titleColor font-primaryMedium leading-5 col-span-3;
}
.sunnova-value {
  @apply text-base text-primary font-primaryBold break-all col-span-1 flex justify-end;
}
.sunnova-btn-finalize {
  @apply w-full hover:shadow-md bg-opacity-80 hover:bg-opacity-95 text-white focus:outline-none text-sm font-primaryMedium py-1.5 px-4 rounded bg-superSecondary;
}
.sunnova-btn-finalize.active {
  @apply bg-primary;
}
.sunnova-list-wrap {
  @apply mb-1.5 w-full;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  gap:1rem;
}
.sunnova-loan-amount {
  @apply text-lg text-titleColor font-primaryBold break-all;
}
.sunnova-loan-label {
  @apply text-xl font-primaryMedium leading-4  whitespace-nowrap text-superSecondary;
}
.sunnova-loan-info {
  @apply flex justify-between gap-4 mt-4 mb-5 flex-row-reverse items-center;
}
.lead-form-dialog.leadformCustom {
  position: relative;
  max-height: 80vh;
  height: auto;
}

.customDotsLoan .react-multi-carousel-track {
  padding-bottom: 60px;
}
@media screen and (max-width: 980px) {
  .lead-form-dialog {
    height: 100vh;
  }
}
.survey-steps-nav > li.active {
  color: var(--primary);
}
.survey-steps-nav > li.active .check-icon {
  display: flex;
}

.survey-steps-nav > li .check-icon {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--primary);
  border-radius: 100%;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.survey-steps-nav > li .step-line {
  position: absolute;
  display: block;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  height: 0.125rem;
}
.survey-steps-nav > li .step-line::after,
.survey-steps-nav > li .step-line::before {
  content: "";
  display: block;
  width: 50%;
  height: 100%;
  --tw-bg-opacity: 1;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  position: absolute;
  top: 0;
}
.survey-steps-nav > li .step-line::before {
  left: 0;
}
.survey-steps-nav > li .step-line::after {
  right: 0;
}
.survey-steps-nav > li:first-child .step-line::before {
  display: none;
}
.survey-steps-nav > li:last-child .step-line::after {
  display: none;
}
.w-184 {
  width: 184px;
}
.lease-container {
  min-height: 15rem;
}

.captureLoaderUI {
  min-height: 80vh;
  max-height: 80vh;
  height: auto;
}
.suntuity-mobile-partner .react-multi-carousel-dot-list {
  bottom: 30px;
}

.bg-blueLight-250 {
  background: #f5f8fc;
}
.bg-blueLight-350 {
  background: #eff4f8;
}
.gray-175 {
  color: #afb9c2;
}
@media screen and (max-width: 768px) {
  .mob-m-h-120 {
    min-height: 120px;
  }
}

.pdf-wrapper .react-pdf__Page__canvas {
  max-width: 1000px;
  height: auto !important;
  width: auto !important;
}

@media screen and (max-width: 639px) {
  .react-pdf__Document.pdf-wrapper {
    overflow: auto;
  }
  .battery-checkbox .checkbox-container .checkbox-mainDiv .check::after {
    left: 7.5px;
    top: 3px;
  }
  .shopnow-landing .h-screen-90 {
    min-height: 80vh;
    height: auto;
  }
  .text-cus-3xl {
    font-size: 1.759rem;
    line-height: 1.2;
  }

  /* .estimate-design.estimateDesignMobile{
  height: auto;
    overflow: inherit;
} */
}
@media screen and (max-width: 500px) {
  .survey-steps-nav > li {
    min-width: 33.33%;
  }
  .survey-steps-nav.survey-steps-nav-whatnext > li {
    min-width: auto;
  }
}
@media screen and (max-width: 413px) {
  .shopnow-landing .h-screen-90 {
    min-height: 76vh;
    height: auto;
  }
  .text-cus-3xl {
    font-size: 1.759rem;
    line-height: 1.2;
  }
}

.battery-dot-carousel .react-multi-carousel-list {
  padding-bottom: 2.25rem;
}
.blog-page-bg {
  background: #f3f5fa;
}
.blog-page-hover-shadow {
  transition: 0.3s;
}
.blog-page-hover-shadow:hover {
  box-shadow: 0 34px 33px -23px rgba(22, 28, 45, 0.13);
}
.pagination-container {
  display: flex;
  justify-content: center;
}
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  grid-gap: 10px;
  gap: 8px;
  background: #fff;
  padding: 5px 0.5rem;
  border-radius: 6px;
}
.pagination .page-number {
  font-size: 1rem;
  font-weight: 700;
  padding: 5px 14px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.5s ease;
  min-height: 34px;
}
.pagination .page-number.next-prev {
  align-items: center;
  display: flex;
  padding: 5px 2px;
}
.pagination .page-number.next-prev:hover {
  background: #fff;
  color: #f7b543;
}
.pagination .page-number:hover {
  color: #fff;
  background: #f3953e;
}
.pagination .active {
  background: #f3953e;
  color: #fff;
}
.pagination .active:hover {
  color: #fff;
  background: #f3953e;
}
.icon-rotate-180 {
  /* filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2); */
  transform: rotate(180deg);
}
.cus-spinner {
  width: 56px;
  height: 56px;
}
.min-h-16 {
  min-height: 4rem;
}
.list--loanListStart.loan-list {
  @apply grid grid-cols-1 xxs:grid-cols-2 2md:grid-cols-3 xxl:grid-cols-4;
}

.loan-list {
  @apply grid grid-cols-1 gap-4;
} 
.loan-list.list-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}
.loan-list.list-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}
.loan-list.list-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.dropdown .dropdown-menu.external-dropdown {
  width: 240px;
}

.layoutOneBanner h1 {
  @apply xs380:text-4xxxl xxs:text-4xl xl:text-5xl xd:text-6xl xl:leading-tight xd:leading-tight font-sfBold text-white mb-2 xxs:mb-5 md:mb-6;
  @media screen and (max-width: 639px) {
    font-size: 1.759rem;
    line-height: 1.2;
  }
}

.layoutOneBanner h3 {
  @apply text-2xl xxs:text-3xl xl:text-4xl xd:text-5xl font-sfLight text-white mb-1;
}

.layoutOneBanner p {
  @apply text-white text-lg leading-6 xxs:text-2xl xd:text-3xl lg:px-12 xd:px-10 mb-6 xxs:mb-5 md:mb-6 lg:mb-8;
}

.layoutTwoBanner h1 {
  @apply text-3xl xxs:text-4xl xl:text-4xxl xd:text-5xl xl:leading-tight xd:leading-tight font-osExtraBold uppercase text-titleColor mb-3 sm:mb-1;
}

.layoutTwoBanner h3 {
  @apply text-2xl xxs:text-3xl xl:text-4xl xd:text-5xl font-sfLight text-white mb-1;
}

.layoutTwoBanner p {
  @apply text-titleNewColor font-osRegular text-lg leading-6 xxs:text-3xl xd:text-3xl mb-6 xxs:mb-5 max-w-xs px-4 sm:px-0 xxs:max-w-3xl mx-auto;
}

.layoutThreeBanner h1 {
  @apply tracking-wide text-2xl lg:text-3xl xl:text-4xxl font-sfRegular 2xl:max-w-2xl xl:max-w-2xl md:max-w-xl mb-3 text-white;
}

.layoutThreeBanner h3 {
  @apply text-2xl xxs:text-3xl xl:text-4xl xd:text-5xl font-sfLight text-white mb-1;
}

.layoutThreeBanner p,
.layoutThreeBanner li {
  @apply text-white block text-xl lg:text-xl xl:text-xl 2xl:text-2xl font-interRegular 2xl:mt-2 max-w-md 2xl:max-w-lg;
}

.homeBannerFormDisclaimer a {
  @apply underline ml-1;
}

.instantThankYouHtml h3 {
  @apply text-2xl md:text-3xl xl:text-2xl mb-4
}

.instantThankYouHtml h1 {
  @apply text-4xl md:text-3xl xl:text-4xl mb-4
}

.instantThankYouHtml p {
  @apply text-base md:text-lg font-sfRegular
}


.co-signer-field .checkbox-container .check {
  top: 0;
  left: 0;
}

.fullFormDisclaimer p {
  @apply xxs:flex font-primaryLight text-xs 2xl:text-xs text-gray-400 flex-col;
}

.fullFormDisclaimer a {
  @apply underline cursor-pointer;
}
.get-started-wrap-notUser {
  max-width: 95%;
  width: 100%;
  @screen xl {
    max-width: 100%;
  }

  @screen xl {
    max-width: 32rem;
  }
}

.global-loader {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.66) no-repeat 0 0;
  z-index: 999;
  justify-content: center;
  align-items: center;
}
.global-loader>img{
  display: block!important;
}
.initial-lead-open .design-tool-address-stripe {
  display: none !important;
}
.dynamic-section > div {
  display: flex;
  width: 100%;
  flex-direction: column;
}
.promoCodeButton {
  @apply w-full text-center text-secondary font-primarySemiBold cursor-pointer hover:shadow-none py-3 my-2 mt-3 border;
}
.MuiSvgIcon-root {
  fill: currentColor;
  width: 0.7em;
  height: 0.7em;
  display: inline-block;
  font-size: 1.5rem;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  user-select: none;
}
@media screen and (max-width: 979px) {
  .join-affiliate-bg {
    max-width: 500px;
    margin: 0 auto;
  }
}
@media screen and (min-width: 980px) {
  .home-estimate-form-wrap .home-banner-form .banner-form-slide {
    gap: 1.5rem;
  }
}
@media screen and (min-width: 768px) {
  .join-affiliate-home-bg {
    min-height: 315px;
  }
  .home-estimate-form-wrap .home-banner-form .banner-form-slide {
    gap: 0.85rem;
  }
}
.join-affiliate-bg {
  background: url("../public/images/affiliate/join-affiliate-poup.png")
    no-repeat;
  background-size: cover;
  background-position: center bottom;
}
.join-affiliate-home-bg {
  background: url("../public/images/affiliate/homepage-popup.png") no-repeat;
  background-size: cover;
  background-position: center bottom;
}
.SectionGetEstimateNow-bg-home {
  background: url("../public/images/unbeatable-ai-powered-bg.jpeg") no-repeat;
  background-size: cover;
  background-position: center bottom;
}
.join-affiliate-home-bg.home-estimate {
  min-height: auto;
  max-width: 560px;
  margin: 0 auto;
}
.home-estimate-form-wrap .home-banner-form .banner-form-slide {
  flex-direction: column;
}
.home-estimate-form-wrap .home-banner-form .banner-form-slide button {
  border-radius: 0.25rem;
  color: #fff;
}
.home-estimate-form-wrap
  .home-banner-form
  .banner-form-slide
  .relative.w-full
  > div {
  border-radius: 0.25rem;
}
.home-estimate-form-wrap > .md\:mt-8 {
  margin-top: 1.25rem;
}
.home-estimate-form-wrap .shopnow-form .css-yk16xz-control > div:first-child,
.home-estimate-form-wrap .shopnow-form .css-1pahdxg-control > div:first-child {
  padding: 0.75rem 0.6rem !important;
}
.loader-layer{
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #0000007a;
}
.w-content{
  width: fit-content;
}
.show-co-signer-text {
  position: relative;
  top: 4px;
}

.cash-finance-container-wrap .list__items {
  @apply flex flex-col shadow p-6 rounded bg-white hover:shadow-xl transition duration-500 ease-in-out transform relative;
}
.cash-finance-container-wrap .sunnova-lable {
  @apply text-base text-lightGray250 font-primaryMedium leading-5 col-span-3;
}
.cash-finance-container-wrap .sunnova-list-wrap {
  @apply mb-3 w-full;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 1rem;
}
.cash-finance-container {
  min-height: 230px;
  height: 100%;
}
.single-page-layout .cash-finance-container {
  min-height: 266px;
}
.bg-skyblue350{
  color: var(--skyBlue350)
}
.finance-card-container .finance-card{
  border: 1px solid transparent;

}
.finance-card-container .active-card{
  border-color: var(--primary);
}
.finance-card-container .inactive-card{
  cursor: pointer;
}
.finance-card-container .inactive-card>*{
  pointer-events: none;
}
.finance-card-container {
  grid-template-columns: repeat(3, minmax(0, 1fr));
  align-items: stretch;

}
.finance-card-container .finance-card-err{
  grid-row: 1 / -1;
  grid-column: 1 / -1;
}
.finance-card-container .finance-card-err+.finance-card-err{
  grid-row: 2 / 2;
  grid-column: 1 / -1;
}


.finance-card-container:not(.finance-card-count-2) .finance-card-cash, 
.finance-card-container:not(.finance-card-count-3) .finance-card-cash,
.finance-card-container.finance-card-count-1 .finance-card-cash {
  max-width: 520px;
  margin: 0 auto;
}
.finance-card-container.finance-card-count-2 .finance-card-cash, 
.finance-card-container.finance-card-count-3 .finance-card-cash{
  max-width: 100%;
}

@media (min-width: 768px){
  .finance-card-count-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    align-items: stretch;
  }
  .finance-card-count-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    align-items: stretch;
  }
  .finance-card-count-3 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    align-items: stretch;
  }
}
@media (min-width: 1024px){
  .finance-card-count-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    align-items: stretch;
  }
  .finance-card-count-2{
    grid-template-columns: repeat(2, minmax(0, 1fr));
    align-items: stretch;
  }
  .finance-card-count-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    align-items: stretch;
  }
}

.finance-card-with-bg .list__items{
  background: #f7f8fd;
  height: 100%;
  display: flex;
  justify-content: space-between;

}



/* .finance-card-container .finance-card:not(.finance-card-3) {
  grid-column: span 6;
}
.finance-card-container .finance-card+.finance-card+.finance-card{
  grid-column: span 4;
} */
.li-dot {
  position: relative;
  padding-left: 14px;
}
.li-dot:before {
  content: '';
  width: 5px;
  height: 5px;
  background: #f3933d;
  position: absolute;
  left: 0;
  margin-top: 0;
  border-radius: 5px;
  top: 7px;
}

.icon-info-circle{
  width: 18px;
  height: 18px;
}
/* }
 .finance-card + .finance-card-err{ grid-row: row 3;}

.finance-card-container .finance-card-err {
  grid-column: 1;
  grid-row: row 1;
}
.finance-card-container .finance-card-err:nth-of-type(2) {
  grid-column: 1;
  grid-row: row 2;
}
.finance-card-container .finance-card-err:nth-of-type(3) {
  grid-column: 1;
  grid-row: row 3;
} */

.info-color{
  color:rgb(66 172 137)

}
.lead-form-dialog__body.lead-form-checknox-wrap label.custom-checkbox input[type="checkbox"] {
  width: 22px;
  height: 22px;
  min-width: 22px;
  min-height: 22px;
}

.max-w-750{
  max-width: 750px;
}
@media screen and (min-width: 600px){
  .finance-box-modal{
    min-width: 500px;
  }
 
}


@media screen and (min-width: 1080px) {
  .min-w-750{
    min-width: 750px;
  }
}
.finance-approval.text-success{
  background: rgb(0 163 0 / 10%);
  color: #00A300;
}
.finance-approval.text-denied{
  background: rgba(239, 68, 68, .10);
  color: #ef4444;
}
.finance-approval.info-color{
  background: rgba(66, 172, 137, .10);
  color: rgb(66, 172, 137);
}
.input-bill-wrap input.average-monthly-bill {
  padding-right: 9rem !important;
}
.coupon-summary-accordion .accordion__button:before{
  /* top: 50%; */
  /* transform: translateY(-50%); */
  margin-top:1.2rem;
}
.coupon-summary-accordion .accordion__panel{
  padding: 0;
}

.solar-summary > .img-summary {
  max-width: 120px;
  width: 100%;
  padding: 8px 4px;
  text-align: center;
  min-height: 126px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
  border-radius: 4px;
}
.finance-box-modal .react-multi-carousel-dot-list{
  position: absolute;
  bottom: 10px;
}
.lead-form-dialog.finance-box-modal {
  max-height: 100%;
  height: 100%;
  padding-bottom: 2rem;
  background: #fff;
}
@media screen and (min-width:768px) {
  .lead-form-dialog.finance-box-modal{
      padding-top: 2rem;
      min-height:382px
  }
}
.lead-form-dialog.finance-box-modal .react-multi-carousel-list {
  flex: 1;
}
@media screen and (min-width: 1101px) {
  
}
.single-page-layout .lead-form-dialog.finance-box-modal{
  margin-top: 1.5rem;
}
.lead-form-dialog.finance-box-modal .react-multi-carousel-list .react-multi-carousel-track .react-multi-carousel-item {
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-modal-next{
  background: #2966AD;
}
.w-100_max-282{
  width: min(100%, 282px);
}
.w-100_max-320{
  width: min(100%, 318px);
}

@media (max-width: 500px) {
  .react-multi-carousel-list {
    width: 100%;
  } 
  .lead-form-dialog.finance-box-modal{
      max-width: 100%;
  }
  /* .lead-form-dialog.finance-box-modal .react-multi-carousel-list .react-multi-carousel-track .react-multi-carousel-item {
    width: 100vw !important;
  } */
}
.min-w-500{
  width: 100%;
}
.w-100_max-360{
  width: 90%;
}
.w-100_max-340{
  width: min(100%, 340px);
}
@media (min-width: 500px){
  .w-100_max-360{
  width: min(100%, 360px);
  }
  .min-w-500{
    min-width: 500px;
  }
}

.finance-box-modal .react-multiple-carousel__arrow--left {
  z-index: 1;
  bottom: -10px;
  left: 50%;
  right: auto;
  margin-left: -76px;
  transform: translate(-32%, -50%);
}
.finance-box-modal .react-multiple-carousel__arrow::before {
  font-size: 16px;
}
.finance-box-modal .react-multiple-carousel__arrow.react-multiple-carousel__arrow--left::before{
position: relative;
left: -1px
}
.finance-box-modal .react-multiple-carousel__arrow.react-multiple-carousel__arrow--right::before{
position: relative;
right: -1px
}
.finance-box-modal .react-multiple-carousel__arrow {
  border-radius: 18px;
  background: #F4F4F4;
  min-width: 30px;
    min-height: 30px;
  box-shadow: none;
}
.finance-box-modal .react-multiple-carousel__arrow--right {
  right: 50%;
  z-index: 1;
  margin-right: -74px;
  bottom: 6px;
}
.finance-box-modal .react-multiple-carousel__arrow:hover{
  background: rgb(243 147 61);
}
.finance-box-modal .css-yk16xz-control > div:first-child, 
.finance-box-modal .css-1pahdxg-control > div:first-child {
  padding: 0.25rem 0.5rem !important;
}
.finance-box-modal .css-1wa3eu0-placeholder{
  top :52%
}
.coupon-close {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%,-50%);
  border-radius: 50%;
  height: 1.3rem;
  width: 1.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.finance-box-modal .react-multi-carousel-track {
  z-index: 99;
}
.min-w-124{min-width: 124px;}

.thank-you-page{
  padding: 5rem;
}
.thank-you-page h1{
  font-size: 3rem!important;
  line-height: 1.25;
  margin-bottom: 1.5rem;
}
.thank-you-page h1 + p >br{
  display: none;
}
/* .thank-you-page .ql-align-center{
  text-align: center;
} */
.thank-you-page p{
  font-size: 1.5rem;
  font-family: var(--primaryRegular),sans-serif;
}
.thank-you-page ol,
.thank-you-page ul{
  list-style: unset;
}
.thank-you-page h2{
  font-size: 2.5rem!important;
}
.thank-you-page h3{
  font-size: 2rem!important;
}
@media screen and (max-width: 1023px) {
  .SectionGetEstimateNow-bg-home .max-w-560{
    max-width: 620px;
    margin: 0 auto;
  }
}

.max-w_360{
  max-width: 360px;
}
.estimate-result-wrap{
  min-height: calc(100vh - 142px);
}

.project-address__placeholder,
.utility-provider__single-value,
.utility-provider__value-container{
  pointer-events: none!important;
}