.layout2Html{
    @apply flex items-center justify-center flex-col gap-4
}
.layout2Html h1,.layout3Html h1{
 @apply  text-3xl lg:text-4xl xl:text-5xl 2xl:text-6xl font-sfBold text-white
}
.layout2Html p,.layout3Html p{
    @apply text-lg sm:text-xl text-white font-sfMedium
}
.layout1Html h1{
@apply text-3xl lg:text-4xl xl:text-5xl xxl:text-6xl font-sfBold text-darkblue350 
}
.layout1Html p{
  @apply  text-lg sm:text-xl text-graylight450 font-sfMedium
}
.bg-layer-white:before {
    content: "";
    background: linear-gradient(88.63deg, #FFFFFF 48.22%, rgba(255, 210, 166, 0) 86.66%);
    z-index: -0;
    height: 100%;
    position: absolute;
    top: 0px;
    bottom: 0px;
    right: 0px;
    left: 0px;
    width: 100%;
    opacity: .7;
    opacity: 0.95;
    filter: drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.25));
}
.bg-layer-blue:before{
    background: linear-gradient(80.58deg, #2D426B 37.38%, rgba(0, 55, 138, 0.45) 96.73%);
    content: "";
    z-index: -0;
    height: 100%;
    position: absolute;
    top: 0px;
    bottom: 0px;
    right: 0px;
    left: 0px;
    width: 100%;
    opacity: .7;
    opacity: 0.95;
    filter: drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.25));
}
.defaultBgLayout{
    background: url("../public/images/layoutBannerBg.png");
    background-size: cover;
    background-position: top center;
    height: 100%;
    min-height: 520px;
    background-repeat: no-repeat;
    @screen sm{
        background-size: 100% 100%;
    }
  }
  .talkToExpertButtonBlue{
    @apply  px-2.5 py-1.5 rounded text-white font-interMedium text-sm border;
    background: #073265;
  }
  .talkToManagerBtn{
    display: flex;
    align-items: center;
  }